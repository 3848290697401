import React, { useState } from 'react'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import PositionFileUploader from 'components/PositionFileUploader/PositionFileUploader';



function AddPositon() {
  const [transactionTypeList, settransactionTypeList] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [accessToken, setAccessToken] = useState()
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    const getTypes = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = { 'Authorization': `Bearer ${accessToken}` }
      let transactionTypeTemp = []
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/transaction_types/`, { headers: headers }).then((value) => {
        value.data.results.forEach((value, index, array) => {
          transactionTypeTemp.push(value.transaction_type)

        })
        settransactionTypeList(transactionTypeTemp)
        setAccessToken(accessToken)
        setLoaded(true)
      })
    }
    if (!loaded) {

      getTypes()
    }
  },[])

  return (
    <div className="content">
      <form>



        <p className="mb-3">Download <a download="positions_template.csv" target="_self" href="blob:https://mark-to-market.com/8d7e3af4-0ac8-454f-995a-c6c8c5062b38">template</a>  file and enter your positions.</p>
        <ul>
          <li className="mb-2">The fields trade_date, transaction_type, and total_consideration_local_ccy are mandatory for all transaction types.</li>
          <li className="mb-2">transaction_type should be one of the following: Accounting Transfer, Buy, Closing Balance, Deposit, Dividend - Equity, Dividend - Fund, Dividend - Loyalty Fund, Dividend - Overseas, Fund Rebate, Interest, LOYALTYU, Management Fees, Sell, Transfer from Income Account, Transfer to Capital Account, Other In, Other Out, Unit Rebate</li>
          <li className="mb-2">The fields security_ticker and unit_cost_local_ccy are mandatory if transaction_type is any of the following: Buy, Sell, Dividend (Any), Fund Rebate, LOYALTU</li>
          <li className="mb-3">The field transaction_quantity is mandatory if transaction_type is any of the following: Buy, Sell</li>
        </ul>
      </form>

<PositionFileUploader/>

    </div>
  )
}

export default AddPositon