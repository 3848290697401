import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Card,CardBody,CardTitle } from 'reactstrap';

import axios from 'axios';
import HomeContext from 'context';

// Imagine you have a list of languages that you'd like to autosuggest.






function renderSuggestion(suggestion) {
  return (
<Card className="my-1 hover-effect security-ticker-suggestions" >
    <CardBody>

        <CardTitle>{suggestion.ticker}</CardTitle>

        

    </CardBody>
</Card>
  );
}

export class AutoSuggestSecurityTicker extends React.Component {
  static contextType = HomeContext
  constructor(props) {
    super();

    this.state = {
      suggestions: [],
      isLoading: false
    };
    
  }

  loadSuggestions(value) {
    // Cancel the previous request
    this.setState({
      isLoading: true
    });
    var self = this;
    axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_securities`, {
      params: {
        q: value
      }
    })
    .then(function (response) {

      self.setState({
        isLoading: false,
        suggestions: response.data
      });
      return;
    })

  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.context.dispatch({type:"CHANGE_SECURITY_TICKER",payload:{securityTicker:newValue}})
  };
    
  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {

    this.setState({
      suggestions: []
    });
  };
  getSuggestionValue(suggestion) {
    this.inputProps.context.dispatch({type:"SEARCH_TICKET",payload:{"securityTicker":suggestion.ticker,"securityName":suggestion.name,"ISIN":suggestion.isin}})
    return suggestion.ticker;
  }
  render() {
    const { suggestions, isLoading } = this.state;
    const inputProps = {
      placeholder: "Enter the ticker name to search",
      value:this.context.security_ticker,
      onChange: this.onChange,
      className:'form-control',
      required: this.props.required,
      context:this.context
    };
    const status = (isLoading ? 'Loading...' : 'Type to load suggestions');
    
    return (
      <div>
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps} />
        <div className="status ml-3">
          {status}
        </div>
      </div>
    );
  }
}
// mustansir14

export default AutoSuggestSecurityTicker