import AutoSuggestSecurityTicker from "components/AutoSuggestSecurityTicker/AutoSuggestSecurityTicker";
import HomeContext from "context";
import React, { useState, useReducer, useContext } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { useAlert } from "react-alert";
import { useAuth0 } from "@auth0/auth0-react";

import axios from "axios";

function AddPositionForm(props) {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const { security_ticker, security_name, ISIN,security_currency ,dispatch } = useContext(HomeContext);
  const [transactionType, settransactionType] = useState("Buy");
  const [securityTicker, setsecurityTicker] = useState(security_ticker);
  const [transactionTypeList, settransactionTypeList] = useState([]);

  const [quantity, setQuantity] = useState("0");
  const [localCCY, setLocalCCY] = useState()
  const [unitCostLocalCCY, setUnitCostLocalCCY] = useState("0");
  const [brokerageLocalCCY, setBrokerageLocalCCY] = useState("0");
  const [transactionTaxLocalCCY, setTransactionTaxLocalCCY] = useState("0");
  const [fxTransactionCostLocalCCY, setFxTransactionCostLocalCCY] = useState("0");
  const [totalConsiderationLocalCCY, setTotalConsiderationLocalCCY] = useState("0");

  const [foreignCCY, setForeignCCY] = useState(security_currency)
  const [unitCostForeignCCY, setUnitCostForeignCCY] = useState()
  const [brokerageForeignCCy, setBrokerageForeignCCY] = useState()
  const [transactionTaxForeignCCY, setTransactionTaxForeignCCY] = useState()
  const [fxTransactionCostForeignCCY, setFxTransactionCostForeignCCY] = useState()
  const [totalConsiderationForeignCCY, setTotalConsiderationForeignCCY] = useState()

  const [fxRate, setFxRate] = useState()

  const [loaded, setLoaded] = useState(false);

  if (securityTicker != security_ticker) {
    setsecurityTicker(security_ticker);
  }
  
  React.useEffect(() => {
    const getAvailableCCY = async () => {

      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
        'Authorization': `Bearer ${accessToken}`

      }

          axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, { headers: headers })
            .then(response2 => {
              setLocalCCY(response2.data.portfolio_ccy)
            })

    }
    getAvailableCCY()
  }, []);

  React.useEffect(() => {
    const getTypes = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = { Authorization: `Bearer ${accessToken}` };
      await axios.get(`${process.env.REACT_APP_BACKEND_API}finance/transaction_types/`, {
          headers: headers,
        })
        .then((value) => {
          let arrTemp = value.data.results
          let temp = arrTemp[0]
          arrTemp[0] = arrTemp[1]
          arrTemp[1] = temp
          settransactionTypeList(arrTemp);
          console.log(value.data.results)
          setLoaded(true);
        });
      
    };
    if (!loaded) {
      getTypes();
    }
  }, []);

  function submitAddPositiobFormLong() {
    let addPositionBtn = document.getElementById("add_position_btn");
    let tradeDate = document.getElementById("trade_date_id").value;
    let settlementDate = document.getElementById("settlement_date_id").value;
    let brokerRefrence = document.getElementById("broker_reference_id").value;
    let transactionDescription = document.getElementById(
      "transaction_description_id"
    ).value;

    let quantity = document.getElementById("quantity_id").value;




    let trimCode = document.getElementById("trim_code_id").value;






    let transactionQuantity = 0;

    if (transactionType === "Sell") {
      transactionQuantity = -parseFloat(quantity.replace(/,/g, ""));
    } else {
      transactionQuantity = parseFloat(quantity.replace(/,/g, ""));
    }

    addPositionBtn.innerText = "Please wait...";
    let payload = {
      user: props.user.email,
      trade_date: tradeDate,
      settlement_date: settlementDate == "" ? null : settlementDate,
      broker_reference: brokerRefrence,
      transaction_description: transactionDescription,
      unit_cost_local_ccy: parseFloat(unitCostLocalCCY?.replace(/,/g, "") ?? 0),
      quantity: parseFloat(quantity) ?? 0,
      brokerage_local_ccy: parseFloat(brokerageLocalCCY?.replace(/,/g, "") ?? 0),
      transaction_tax_local_ccy: parseFloat(transactionTaxLocalCCY?.replace(/,/g, "") ?? 0),
      fx_transaction_cost_local_ccy: parseFloat(fxTransactionCostLocalCCY?.replace(/,/g, "") ?? 0),
      total_consideration_local_ccy: parseFloat(totalConsiderationLocalCCY?.replace(/,/g, "") ?? 0),
      trim_code: trimCode,
      transaction_quantity: transactionQuantity,
      transaction_type: transactionType,
      security: security_ticker,
      unit_cost_foreign_ccy: parseFloat(unitCostForeignCCY?.replace(/,/g, "") ?? 0),
      brokerage_foreign_ccy: parseFloat(brokerageForeignCCy?.replace(/,/g, "") ?? 0),
      transaction_tax_foreign_ccy: parseFloat(transactionTaxForeignCCY?.replace(/,/g, "") ?? 0),
      fx_transaction_cost_foreign_ccy: parseFloat(fxTransactionCostForeignCCY?.replace(/,/g, "") ?? 0),
      total_consideration_foreign_ccy: parseFloat(totalConsiderationForeignCCY?.replace(/,/g, "") ?? 0),
      fx_rate: parseFloat(fxRate?.replace(/,/g, "") ?? 0),
      local_ccy: localCCY ? localCCY.replace(/,/g, "") : 0,
      foreign_ccy: foreignCCY ? foreignCCY.replace(/,/g, "") : 0
    };

    let headers = { Authorization: `Bearer ${props.accessToken}`.toString() };
    console.log(payload);
    let url = `${process.env.REACT_APP_BACKEND_API}finance/positions/`;

    axios
      .post(url, payload, { headers: headers })
      .then((response) => {
        alert.show("Position added");
        addPositionBtn.innerText = "Add position";
      })
      .catch((err) => {
        console.log(err);
        alert.error("Server side error occurs.");
        addPositionBtn.innerText = "Add position";
      });
  }
  function submitAddPositiobFormShort() {
    let tradeDate = document.getElementById("trade_date_id").value;
    let settlementDate = document.getElementById("settlement_date_id").value;
    let brokerRefrence = document.getElementById("broker_reference_id").value;
    let transactionDescription = document.getElementById(
      "transaction_description_id"
    ).value;

    let totalConsiderationLocalCCY = document.getElementById(
      "total_consideration_local_ccy"
    ).value;
    let trimCode = document.getElementById("trim_code_id").value;
    let addPositionBtn = document.getElementById("add_position_btn");
    addPositionBtn.innerText = "Please wait...";
    let payload = {
      user: props.user.email,
      trade_date: tradeDate,
      settlement_date: settlementDate == "" ? null : settlementDate,
      broker_reference: brokerRefrence,
      transaction_description: transactionDescription,
      unit_cost_local_ccy: 0,
      quantity: null,
      brokerage_local_ccy: 0,
      transaction_tax_local_ccy: 0,
      fx_transaction_cost_local_ccy: 0,
      total_consideration_local_ccy: totalConsiderationLocalCCY,
      trim_code: trimCode,
      transaction_quantity: null,
      transaction_type: transactionType,
      security: null,
      unit_cost_foreign_ccy: 0,
      brokerage_foreign_ccy: 0,
      transaction_tax_foreign_ccy: 0,
      fx_transaction_cost_foreign_ccy: 0,
      total_consideration_foreign_ccy: 0,
      fx_rate: 0,
      local_ccy: "",
      foreign_ccy: "",
    };
    let headers = { Authorization: `Bearer ${props.accessToken}` };
    console.log(payload);
    let url = `${process.env.REACT_APP_BACKEND_API}finance/positions/`;

    axios
      .post(url, payload, { headers: headers })
      .then((response) => {
        alert.show("Position added");
        addPositionBtn.innerText = "Add position";
      })
      .catch((err) => {
        console.log(err);
        alert.error("Server side error occurs!");
        addPositionBtn.innerText = "Add position";
      });
  }
  switch (transactionType) {
    case "Buy":
    case "Dividend - Equity":
    case "Dividend - Fund":
    case "Dividend - Loyalty Fund":
    case "Dividend - Overseas":
    case "Fund Rebate":
    case "LOYALTYU":
    case "Sell":
      return (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitAddPositiobFormLong();
            }}
          >
            <div key="1" className="form-row">
              <FormGroup className="col-md-4">
                <Label htmlFor="inputEmail4" className="text-white">
                  Transaction type
                </Label>
                <Input
                  defaultValue={transactionType}
                  onChange={(e) => {
                    settransactionType(e.target.value);
                  }}
                  type="select"
                  name="select"
                  id="transaction_type_id"
                >
                  {transactionTypeList.map((e, index) => {
                    return (
                      <option key={index} value={e.transaction_type}>
                        {e.transaction_type}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label htmlFor="trade_date_id" className="text-white">
                  Trade Date
                </Label>
                <Input
                  type="date"
                  id="trade_date_id"
                  placeholder=""
                  required
                  autoComplete="off"
                />
              </FormGroup>

              <FormGroup className="col-md-4">
                <Label htmlFor="settlement_date_id" className="text-white">
                  Settlement Date
                </Label>
                <Input
                  type="date"
                  id="settlement_date_id"
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label htmlFor="inputPassword4" className="text-white">
                  Security ticker
                </Label>
                <AutoSuggestSecurityTicker
                  required={true}
                  securityTicker={securityTicker}
                  onChange={(e) => {
                    dispatch({
                      type: "CHANGE_SECURITY_TICKER",
                      payload: e.value,
                    });
                  }}
                />
              </FormGroup>

              <FormGroup className="col-md-4">
                <Label htmlFor="inputPassword4" className="text-white">
                  Security name
                </Label>
                <Input
                  type="text"
                  id="inputPassword4"
                  value={security_name}
                  onChange={(e) => {
                    dispatch({
                      type: "CHANGE_SECURITY_NAME",
                      payload: { securityName: e.value },
                    });
                  }}
                  placeholder=""
                  autoComplete="off"
                  disabled
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label htmlFor="inputPassword4" className="text-white">
                  ISIN
                </Label>
                <Input
                  type="text"
                  value={ISIN}
                  onChange={(e) => {
                    dispatch({
                      type: "CHANGE_ISIN",
                      payload: { ISIN: e.value },
                    });
                  }}
                  placeholder=""
                  autoComplete="off"
                  disabled
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label htmlFor="broker_reference_id" className="text-white">
                  Broker refrence
                </Label>
                <Input type="text" id="broker_reference_id" placeholder="" />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label
                  htmlFor="transaction_description_id"
                  className="text-white"
                >
                  Transaction description
                </Label>
                <Input
                  type="text"
                  id="transaction_description_id"
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>

              <FormGroup className="col-md-4">
                <Label htmlFor="quantity_id" className="text-white">
                  Quantity
                </Label>
                <Input
                  type="number"
                  value={quantity == "0" ? "" : quantity}
                  required={
                    transactionType === "Buy" || transactionType === "Sell"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setQuantity(e.target.value);
                    let totalConsiderationLocalCCYTemp =
                      parseFloat(e.target.value.replace(/,/g, "")) * parseFloat(unitCostLocalCCY.replace(/,/g, "")) +
                      parseFloat(brokerageLocalCCY.replace(/,/g, "")) +
                      parseFloat(transactionTaxLocalCCY.replace(/,/g, "")) +
                      parseFloat(fxTransactionCostLocalCCY.replace(/,/g, ""));
                    if (transactionType === "Buy")
                      totalConsiderationLocalCCYTemp *= -1;
                    setTotalConsiderationLocalCCY(
                      totalConsiderationLocalCCYTemp.toLocaleString(undefined, { maximumFractionDigits: 2 })
                    );
                  }}
                  id="quantity_id"
                  placeholder=""
                />
              </FormGroup>
            </div>
            <div className="row">

              <h4 className="col-md-12 text-info"><span className="text-white">Local CCY</span> --------------------</h4>
            </div>
            <div className="row" style={{ alignItems: "flex-end" }}>
              <FormGroup className="col-md-2">
                <Label htmlFor="local_ccy_id" className="text-white">
                  Local ccy
                </Label>
                <Input
                  type="text"
                  id="local_ccy_id"
                  value={localCCY}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      console.log(val)
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setLocalCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    // setLocalCCY(incomingVal)
                  }}
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label htmlFor="unit_cost_local_ccy_id" className="text-white">
                  Unit cost local ccy
                </Label>
                <Input
                  type="text"
                  id="unit_cost_local_ccy_id"
                  value={unitCostLocalCCY == 0 ? "" : unitCostLocalCCY}
                  onBlur={
                    (e) => {
                      if (e.target.value.toLocaleLowerCase() === "n/a") {
                        setUnitCostLocalCCY("n/a")
                        return
                      }
                      let val = parseFloat(e.target.value.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setUnitCostLocalCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    console.log(e.target.value)
                    setUnitCostLocalCCY(e.target.value);

                    let totalConsiderationLocalCCYTemp =
                      (parseFloat(quantity.replace(/,/g, "")) * parseFloat(e.target.value.replace(/,/g, ""))) +
                      parseFloat(brokerageLocalCCY.replace(/,/g, "")) +
                      parseFloat(transactionTaxLocalCCY.replace(/,/g, "")) +
                      parseFloat(fxTransactionCostLocalCCY.replace(/,/g, ""));
                    if (transactionType === "Buy") {
                      totalConsiderationLocalCCYTemp = -1 * totalConsiderationLocalCCYTemp;
                    }
                    setTotalConsiderationLocalCCY(
                      totalConsiderationLocalCCYTemp.toLocaleString(undefined, { maximumFractionDigits: 2 })
                    );
                    console.log(quantity, brokerageLocalCCY, transactionTaxLocalCCY, fxTransactionCostLocalCCY, totalConsiderationLocalCCYTemp)
                  }}
                  placeholder=""
                  required
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label htmlFor="brokerage_local_ccy" className="text-white">
                  Brokerage local ccy
                </Label>
                <Input
                  type="text"
                  className="mt-2"
                  id="brokerage_local_ccy_id"
                  value={brokerageLocalCCY == 0 ? "" : brokerageLocalCCY}
                  onBlur={
                    (e) => {
                      let val = parseFloat(e.target.value.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setBrokerageLocalCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    setBrokerageLocalCCY(e.target.value);
                    let quantityNum = parseFloat(quantity.replace(/,/g, "")) || 0;
                    let unitCostLocalCCYNum = parseFloat(unitCostLocalCCY.replace(/,/g, "")) || 0;
                    let brokerageLocalCCYNum = e.target.value.toLowerCase() === "n/a" ? 0 : parseFloat(e.target.value.replace(/,/g, "")) || 0;
                    let transactionTaxLocalCCYNum = parseFloat(transactionTaxLocalCCY.replace(/,/g, "")) || 0;
                    let fxTransactionCostLocalCCYNum = parseFloat(fxTransactionCostLocalCCY.replace(/,/g, "")) || 0;

                    let totalConsiderationLocalCCYTemp =
                      (quantityNum * unitCostLocalCCYNum) + brokerageLocalCCYNum + transactionTaxLocalCCYNum + fxTransactionCostLocalCCYNum;

                    if (transactionType === "Buy") {
                      totalConsiderationLocalCCYTemp *= -1;
                    }

                    if (!isNaN(totalConsiderationLocalCCYTemp)) {
                      setTotalConsiderationLocalCCY(
                        totalConsiderationLocalCCYTemp.toLocaleString(undefined, { maximumFractionDigits: 2 })
                      );
                    } else {
                      // handle NaN value
                    }
                  }}

                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="transaction_tax_local_ccy"
                  className="text-white text-sm"
                >
                  Transaction tax local ccy
                </Label>
                <Input
                  type="text"
                  id="transaction_tax_local_ccy_id"

                  placeholder=""
                  value={transactionTaxLocalCCY == 0 ? "" : transactionTaxLocalCCY}
                  onBlur={
                    (e) => {
                      let val = parseFloat(e.target.value.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setTransactionTaxLocalCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    setTransactionTaxLocalCCY(e.target.value);
                    let quantityNum = parseFloat(quantity.replace(/,/g, "")) || 0;
                    let unitCostLocalCCYNum = parseFloat(unitCostLocalCCY.replace(/,/g, "")) || 0;
                    let brokerageLocalCCYNum = parseFloat(brokerageLocalCCY.replace(/,/g, "")) || 0;
                    let transactionTaxLocalCCYNum = e.target.value.toLowerCase() === "n/a" ? 0 : parseFloat(e.target.value.replace(/,/g, "")) || 0;
                    let fxTransactionCostLocalCCYNum = parseFloat(fxTransactionCostLocalCCY.replace(/,/g, "")) || 0;

                    let totalConsiderationLocalCCYTemp =
                      (quantityNum * unitCostLocalCCYNum) + brokerageLocalCCYNum + transactionTaxLocalCCYNum + fxTransactionCostLocalCCYNum;

                    if (transactionType === "Buy") {
                      totalConsiderationLocalCCYTemp *= -1;
                    }

                    if (!isNaN(totalConsiderationLocalCCYTemp)) {
                      setTotalConsiderationLocalCCY(
                        totalConsiderationLocalCCYTemp.toLocaleString(undefined, { maximumFractionDigits: 2 })
                      );
                    } else {
                      // handle NaN value
                    }
                  }}

                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="fx_transaction_cost_local_ccy"
                  className="text-white"
                >
                  Fx transaction cost local ccy
                </Label>
                <Input
                  type="text"
                  value={fxTransactionCostLocalCCY == 0 ? "" : fxTransactionCostLocalCCY}
                  onBlur={
                    (e) => {
                      let val = parseFloat(e.target.value.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setFxTransactionCostLocalCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    setFxTransactionCostLocalCCY(e.target.value);
                    let quantityNum = parseFloat(quantity.replace(/,/g, "")) || 0;
                    let unitCostLocalCCYNum = parseFloat(unitCostLocalCCY.replace(/,/g, "")) || 0;
                    let brokerageLocalCCYNum = parseFloat(brokerageLocalCCY.replace(/,/g, "")) || 0;
                    let transactionTaxLocalCCYNum = parseFloat(transactionTaxLocalCCY.replace(/,/g, "")) || 0;
                    let fxTransactionCostLocalCCYNum = parseFloat(e.target.value.replace(/,/g, "")) || 0;

                    let totalConsiderationLocalCCYTemp =
                      (quantityNum * unitCostLocalCCYNum) + brokerageLocalCCYNum + transactionTaxLocalCCYNum + fxTransactionCostLocalCCYNum;

                    if (transactionType === "Buy") {
                      totalConsiderationLocalCCYTemp *= -1;
                    }

                    if (!isNaN(totalConsiderationLocalCCYTemp)) {
                      setTotalConsiderationLocalCCY(
                        totalConsiderationLocalCCYTemp.toLocaleString(undefined, { maximumFractionDigits: 2 })
                      );
                    } else {
                      // handle NaN value
                    }
                  }}
                  id="fx_transaction_cost_local_ccy_id"
                  placeholder=""
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="total_consideration_local_ccy"
                  className="text-white"
                >
                  Total consideration local ccy
                </Label>
                <Input
                  disabled
                  type="text"
                  className="text-white"
                  id="total_consideration_local_ccy_id"
                  presicion={4}
                  step="0.0001"
                  value={totalConsiderationLocalCCY == 0 ? "" : totalConsiderationLocalCCY}
                  placeholder=""
                  required
                  autoComplete="off"
                />
              </FormGroup>
            </div>
            <div className="row" style={{display:security_currency==localCCY||localCCY=="GBP" && security_currency=="GBX"?"none":""}}>
              <h4 className="col-md-12 text-info"><span className="text-light">Foreign CCY </span>-------------------</h4>
            </div>
            <div className="row" style={{ alignItems: "flex-end",display:security_currency==localCCY||localCCY=="GBP" && security_currency=="GBX"?"none":""}}>
              <FormGroup className="col-md-2">
                <Label htmlFor="foreign_ccy_id" className="text-white">
                  Foreign ccy
                </Label>
                <Input
                  type="text"
                  id="foreign_ccy_id"
                  placeholder=""
                  autoComplete="off"
                  value={security_currency}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      console.log(val)
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setForeignCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    // setForeignCCY(incomingVal)
                  }}
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="unit_cost_foreign_ccy_id"
                  className="text-white"
                >
                  Unit cost foreign ccy
                </Label>
                <Input
                  type="text"
                  id="unit_cost_foreign_ccy_id"
                  placeholder=""
                  value={unitCostForeignCCY}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      console.log(val)
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setUnitCostForeignCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    setUnitCostForeignCCY(incomingVal)
                  }}
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="brokerage_foreign_ccy_id"
                  className="text-white"
                >
                  Brokerage foreign ccy
                </Label>
                <Input
                  type="text"
                  id="brokerage_foreign_ccy_id"
                  placeholder=""
                  autoComplete="off"
                  value={brokerageForeignCCy}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      console.log(val)
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setBrokerageForeignCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    setBrokerageForeignCCY(incomingVal)
                  }}

                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="transaction_tax_foreign_ccy_id"
                  className="text-white"
                >
                  Transaction tax foreign ccy
                </Label>
                <Input
                  type="text"
                  id="transaction_tax_foreign_ccy_id"
                  placeholder=""
                  value={transactionTaxForeignCCY}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      console.log(val)
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setTransactionTaxForeignCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    setTransactionTaxForeignCCY(incomingVal)
                  }}
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label
                  htmlFor="fx_transaction_cost_foreign_ccy_id"
                  className="text-white"
                >
                  Fx transaction cost foreign ccy
                </Label>
                <Input
                  type="text"
                  id="fx_transaction_cost_foreign_ccy_id"
                  placeholder=""
                  autoComplete="off"
                  value={fxTransactionCostForeignCCY}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setFxTransactionCostForeignCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    setFxTransactionCostForeignCCY(incomingVal)
                  }}
                />
              </FormGroup>

              <FormGroup className="col-md-2">
                <Label
                  htmlFor="total_consideration_foreign_ccy_id"
                  className="text-white"
                >
                  Total consideration foreign ccy
                </Label>
                <Input
                  type="text"
                  id="total_consideration_foreign_ccy_id"
                  placeholder=""
                  autoComplete="off"
                  value={totalConsiderationForeignCCY}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setTotalConsiderationForeignCCY(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    setTotalConsiderationForeignCCY(incomingVal)
                  }}
                />
              </FormGroup>
              <FormGroup className="col-md-2">
                <Label htmlFor="fx_rate_id" className="text-white">
                  Fx rate
                </Label>
                <Input type="text" step=".0001" id="fx_rate_id" placeholder=""
                  value={fxRate}
                  onBlur={
                    (e) => {
                      let incomingVal = e.target.value
                      let val = parseFloat(incomingVal.replace(/,/g, "")).toLocaleString(undefined, { maximumFractionDigits: 5 })
                      if (!isNaN(parseFloat(val.replace(/,/g, "")))) {
                        setFxRate(val)
                      }
                    }
                  }
                  onChange={(e) => {
                    let incomingVal = e.target.value
                    console.log(incomingVal)
                    let val = parseFloat(incomingVal).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    setFxRate(incomingVal)
                  }}
                />
              </FormGroup>


            </div>
            <div key="7" className="form-row">
              <FormGroup style={{ display: "none" }} className="col-md-3">
                <Label htmlFor="trim_code_id" className="text-white">
                  Trim code
                </Label>
                <Input
                  type="text"
                  id="trim_code_id"
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
            </div>

            <Button type="submit" color="primary" id="add_position_btn">
              Add position
            </Button>
          </form>
        </>
      );

    case "Closing Balance":
    case "Deposit":
    case "Accounting Transfer":
    case "Interest":
    case "Management Fees":
    case "Transfer from Income Account":
    case "Transfer to Capital Account":
    case "Other In":
    case "Other Out":
    case "Unit Rebate":
      return (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitAddPositiobFormShort();
            }}
          >
            <div className="form-row">
              <FormGroup key="9" className="col-md-3">
                <Label htmlFor="inputEmail4" className="text-white">
                  Transaction type
                </Label>
                <Input
                  defaultValue={transactionType}
                  onChange={(e) => {
                    settransactionType(e.target.value);
                  }}
                  type="select"
                  name="select"
                  id="transaction_type_id"
                >
                  {transactionTypeList.map((e, index) => {
                    return (
                      <option key={index} value={e.transaction_type}>
                        {e.transaction_type}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup key="10" className="col-md-3">
                <Label htmlFor="trade_date_id" className="text-white">
                  Trade Date
                </Label>
                <Input
                  type="date"
                  id="trade_date_id"
                  placeholder=""
                  required
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup key="11" className="col-md-3">
                <Label htmlFor="settlement_date_id" className="text-white">
                  Settlement Date
                </Label>
                <Input
                  type="date"
                  id="settlement_date_id"
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup key="12" className="col-md-3">
                <Label htmlFor="broker_reference_id" className="text-white">
                  Broker refrence
                </Label>
                <Input type="text" id="broker_reference_id" placeholder="" />
              </FormGroup>
              <FormGroup key="13" className="col-md-3">
                <Label
                  htmlFor="transaction_description_id"
                  className="text-white"
                >
                  Transaction description
                </Label>
                <Input
                  type="text"
                  id="transaction_description_id"
                  presicion={2}
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup key="14" className="col-md-3">
                <Label
                  htmlFor="total_consideration_local_ccy"
                  className="text-white"
                >
                  Total consideration local ccy
                </Label>
                <Input
                  presicion={4}
                  step="0.0001"
                  type="number"
                  id="total_consideration_local_ccy"
                  placeholder=""
                  required
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup
                key="15"
                style={{ display: "none" }}
                className="col-md-3"
              >
                <Label htmlFor="trim_code_id" className="text-white">
                  Trim code
                </Label>
                <Input
                  type="text"
                  id="trim_code_id"
                  placeholder=""
                  autoComplete="off"
                />
              </FormGroup>
            </div>

            <Button type="submit" color="primary" id="add_position_btn">
              Add position
            </Button>
          </form>
        </>
      );
    case "None":
      return (
        <>
          <form>
            <div className="form-row">
              <FormGroup key="16" className="col-md-3">
                <Label htmlFor="inputEmail4" className="text-white">
                  Transaction type
                </Label>
                <Input
                  defaultValue={transactionType}
                  onChange={(e) => {
                    settransactionType(e.target.value);
                  }}
                  type="select"
                  name="select"
                  id="inputState"
                >
                  <option value="None">None</option>
                  <option value="Accounting transfer">
                    Accounting transfer
                  </option>
                  <option value="Buy">Buy</option>
                  <option value="Closing balance">Closing balance</option>
                  <option value="Deposit">Deposit</option>
                  <option value="Devidend-equity">Devidend-equity</option>
                  <option value="Devident-fund">Devidend-fund</option>
                  <option value="Devident-loyalty fund">
                    Devidend-loyalty fund
                  </option>
                  <option value="Devident-overseas">Devidend-overseas</option>
                  <option value="Fund rebate">Fund rebate</option>
                  <option value="Interest">Interest</option>
                  <option value="LOYALTYU">LOYALTYU</option>
                  <option value="Management fees">Management fees</option>
                  <option value="Sell">Sell</option>
                  <option value="Transfer from income account">
                    Transfer from income account
                  </option>
                  <option value="Transfer to capital account">
                    Transfer to capital account
                  </option>
                  <option value="Other in">Other in</option>
                  <option value="Other out">Other out</option>
                  <option value="Unit rebate">Unit rebate</option>
                </Input>
              </FormGroup>
            </div>
          </form>
        </>
      );
  }
}

export default AddPositionForm;
