import LoginButton from 'components/LoginButton/LoginButton'
import React from 'react'
import "../../assets/css/not-authorized.css"

function NotAuthorized() {
  return (
    
    <>

    
    <div className="height-100 page-hero flex-column d-flex d-flex-col align-items-center justify-content-center">

    <div className="h1 text-info">Please wait....</div>

    <LoginButton className="btn btn-info"/>
    </div>
    </>

  )
}

export default NotAuthorized