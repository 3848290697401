import React from 'react'
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import {
  Card,
  CardBody,
  Button,
  Badge,
  Table
} from "reactstrap";
import Papa from "papaparse"
import "../../assets/css/fileUploader.css"
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from 'react-alert'
var moment = require('moment')

const fileTypes = ["CSV"];

function PositionFileUploader() {

  const mandatoryTransactionTypeForPoint3 = ['Buy', 'Sell']
  const mandatoryTransactionTypeForPoint4 = ['Buy', 'Sell']
const [file, setFile] = React.useState(null);

  const [modalOpen, setModalOpen] = React.useState(false)
  const [showLoader, setShowLoader] = React.useState(false)
  const [uploadMessage, setUploadMessage] = React.useState("No file chosen, select csv file to validate.")
  const [invalidData, setInvalidData] = React.useState([])
  const [duplicateInfo, setDuplicateInfo] = React.useState([])
  const [headers, setHeaders] = React.useState([])
  const [transactionType, setTransactionType] = React.useState([])
  const [showUploadBtn, setShowUploadBtn] = React.useState(false)
  const [finalPayload, setFinalPayload] = React.useState([])
  const { getAccessTokenSilently } = useAuth0();
  const [availableCCY, setAvailableCCY] = React.useState([])
  const [duplicateData, setDuplicateData] = React.useState()
  const [duplicate, setDuplicate] = React.useState(false)

  const [securityData, setSecurityData] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState("")

  const alert = useAlert()

  React.useEffect(() => {
    const getAvailableCCY = async () => {

      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
        'Authorization': `Bearer ${accessToken}`

      }
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_currencies`, { headers: headers })
        .then(response1 => {
          let tempArr = []
          for (let index = 0; index < response1.data.length; index++) {
            const element = response1.data[index];
            tempArr.push(element.currency)
          }
          setAvailableCCY(tempArr)
        })
    }
    getAvailableCCY()
  }, []);
  React.useEffect(() => {
    const getTypes = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = { 'Authorization': `Bearer ${accessToken}` }
      let transactionTypeTemp = []
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/transaction_types/`, { headers: headers }).then((value) => {
        value.data.results.forEach((value, index, array) => {
          transactionTypeTemp.push(value.transaction_type)

        })
        setTransactionType(transactionTypeTemp)
        console.log(transactionTypeTemp)
      })
    }

    getTypes()
  }, [])
  function containsOnlyNumbers(str) {
    return /^-?[0-9]\d*(\.\d+)?$/.test(str);
  }
  function containsAllPositiveNegativeNumbers(str) {
    return /^-?[0-9]\d*(\.\d+)?$/.test(str);
  }

  const handleFileUpload = (f) => {
    setShowUploadBtn(false)
    setUploadMessage("Verifying...")
    // setShowLoader(true)
    async function readCSV(e) {
      const files = e;
      if (files) {
        return new Promise((resolve, reject) => {
          Papa.parse(files[0], {

            complete(results, files) {
              resolve(results.data)
            },
            error(err, files) {
              reject(err)
            }
          })
        })

      }

    }

    async function formatDataAndValidate(data) {
      const checkDateFormat = (cellData) => {
        if (moment(cellData, 'DD/MM/YYYY', true).isValid() || moment(cellData, 'YYYY/MM/DD', true).isValid() || moment(cellData, 'YYYY-MM-DD', true).isValid() || moment(cellData, 'DD-MM-YYYY', true).isValid()) {
          return true
        } else {
          return false
        }
      }
      const checkQuantityConfermed = (cellData, transactionType) => {

        if (transactionType === "Buy" || transactionType === "Sell") {
          if (cellData && !containsOnlyNumbers(cellData)) {
            return { resultQuantity: false, errorQuantity: "quantity should be numerical" }
          }
          if (!cellData) {
            console.log(cellData)
            return { resultQuantity: false, errorQuantity: "quantity is mandatory due to transaction type" }

          }
        }
        if (cellData && !containsOnlyNumbers(cellData)) {
          if (cellData === 'n/a') {
            return { resultQuantity: true, errorQuantity: "" }

          }
          return { resultQuantity: false, errorQuantity: "quantity should be numerical value" }
        }
        return { resultQuantity: true, errorQuantity: "" }

      }
      const checkUnitCostLocalCCYConfermed = (cellData, transactionType) => {
        // let err=""

        if (mandatoryTransactionTypeForPoint3.indexOf(transactionType) !== -1) {
          if (containsOnlyNumbers(cellData)) {
            return { result: true, error: "" }

          } else {

            return { result: false, error: "numerical data is mandatory" }
          }
        } else {
          if (cellData && !containsOnlyNumbers(cellData)) {
            if (cellData === 'n/a') {
              return { result: true, error: "" }

            }
            return { result: false, error: "please give numerical value" }
          }

          return { result: true, error: "" }

        }
      }
      const checkSecurityTickerConfermed = (cellData, transactionType) => {
        // let err=""

        if (mandatoryTransactionTypeForPoint3.indexOf(transactionType) !== -1) {
          if (cellData === 'n/a') {
            return { resultST: false, errorST: "plese provide valid sicurity_ticker" }

          }
          if (!cellData) {
            return { resultST: false, errorST: "security_ticker is mandatory due to transaction type" }

          } else {
            return { resultST: true, errorST: "" }

          }

        }
        return { resultST: true, errorST: "" }


      }


      return new Promise((resolve, reject) => {
        // console.log(data)
        let invalidDataTemp = []
        let duplicateDataTemp = []
        let headers = data[0]
        const transactionTypeIndex = headers.indexOf('transaction_type')
        let rowData = []
        let objData
        let payloadObjData
        let takeRowData = false
        let duplicateRowData = false
        let payload = []
        for (let index = 1; index < data.length; index++) {
          const row = data[index];
          const indexRow = index
          rowData = []
          if (row.length > 1) {



            payloadObjData = {}
            for (let index = 0; index < row.length; index++) {
              objData = {}
              const cell = row[index];
              const headerName = headers[index]
              const transactionTypeLive = row[transactionTypeIndex]
              switch (headerName.toLowerCase()) {
                case "trade_date":
                  if (checkDateFormat(cell)) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ''
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = 'date format is not correct'
                    takeRowData = true
                  }
                  payloadObjData['trade_date'] = cell
                  break;

                case "settlement_date":
                  if (cell && checkDateFormat(cell)) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell === 'n/a' || cell === '' ? null : cell
                    objData["error"] = ''
                  } else {
                    if (cell === 'n/a' || !cell) {
                      objData["row_no"] = indexRow
                      objData["value"] = cell === 'n/a' || cell === '' ? null : cell
                      objData["error"] = ''

                    } else {
                      objData["row_no"] = indexRow
                      objData["value"] = cell
                      objData["error"] = 'date format is not correct'
                      takeRowData = true

                    }
                  }
                  payloadObjData['settlement_date'] = cell === 'n/a' || cell === '' ? null : cell

                  break;

                case "broker_reference":
                  objData["row_no"] = indexRow
                  objData["value"] = cell
                  objData["error"] = ''
                  payloadObjData['broker_reference'] = cell

                  break;

                case "transaction_description":
                  objData["row_no"] = indexRow
                  objData["value"] = cell
                  objData["error"] = ''
                  payloadObjData['transaction_description'] = cell

                  break;

                case "unit_cost_local_ccy":
                  let { result, error } = checkUnitCostLocalCCYConfermed(parseFloat(cell.replace(",", "")), transactionTypeLive)

                  if (result) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ''
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = parseFloat(cell.replace(",", ""))
                    objData["error"] = error
                    takeRowData = true
                  }
                  payloadObjData['unit_cost_local_ccy'] = cell === 'n/a' || cell === '' ? null : cell.replace(',', '')

                  break

                case "quantity":
                case "transaction_quantity":
                  let { resultQuantity, errorQuantity } = checkQuantityConfermed(parseFloat(cell.replace(",", "")), transactionTypeLive)
                  if (resultQuantity) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ''
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = parseFloat(cell.replace(",", ""))
                    objData["error"] = errorQuantity
                    takeRowData = true
                  }
                  payloadObjData[headerName.toLowerCase()] = cell === 'n/a' || cell === '' ? null : cell.replace(',', '')

                  break
                case "brokerage_local_ccy":
                case "transaction_tax_local_ccy":
                case "fx_transaction_cost_local_ccy":
                case "total_consideration_foreign_ccy":
                case "fx_rate":

                  if (cell && !containsAllPositiveNegativeNumbers(parseFloat(cell.replace(",", "")))) {
                    if (cell === 'n/a') {
                      objData["row_no"] = indexRow
                      objData["value"] = cell
                      objData["error"] = ''
                    } else {
                      objData["row_no"] = indexRow
                      objData["value"] = parseFloat(cell.replace(",", ""))
                      objData["error"] = 'should be numerical value'
                      takeRowData = true
                    }
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ''
                  }
                  payloadObjData[headerName.toLowerCase()] = cell === 'n/a' || cell === '' ? null : cell.replace(',', '')

                  break;

                case "local_ccy":
                case "foreign_ccy":
                  if (cell) {
                    if (/^[a-zA-Z]+$/.test(cell)) {

                      if (cell.length === 3) {
                        if (availableCCY.indexOf(cell.toUpperCase()) !== -1) {

                          objData["row_no"] = indexRow
                          objData["value"] = cell.toUpperCase()
                          objData["error"] = ''
                        } else {
                          objData["row_no"] = indexRow
                          objData["value"] = cell
                          objData["error"] = 'this ccy is not available'
                          takeRowData = true

                        }
                      } else {
                        objData["row_no"] = indexRow
                        objData["value"] = cell
                        objData["error"] = 'it should be 3 characters'
                        takeRowData = true

                      }
                    } else {
                      objData["row_no"] = indexRow
                      objData["value"] = cell
                      objData["error"] = 'it should be alphabets'
                      takeRowData = true

                    }
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ''
                  }
                  break
                case "total_consideration_local_ccy":

                  if (!cell) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = 'mandatory field'
                    takeRowData = true
                  } else {
                    if (!containsAllPositiveNegativeNumbers(parseFloat(cell.replace(",", "")))) {
                      objData["row_no"] = indexRow
                      objData["value"] = cell
                      objData["error"] = 'should be numerical value'
                      takeRowData = true
                    } else {
                      objData["row_no"] = indexRow
                      objData["value"] = cell
                      objData["error"] = ''
                    }
                  }
                  payloadObjData[headerName.toLowerCase()] = cell.replace(',', '')

                  break

                case "trim_code":

                  if (cell) {
                    if (cell.length > 1) {
                      objData["row_no"] = indexRow
                      objData["value"] = cell
                      objData["error"] = "trim_code should be single alphabet"
                      takeRowData = true
                    } else {
                      if (!/^[a-z]+$/i.test(cell)) {
                        objData["row_no"] = indexRow
                        objData["value"] = cell
                        objData["error"] = "trim_code should be alphabet"
                        takeRowData = true
                      } else {
                        objData["row_no"] = indexRow
                        objData["value"] = cell
                        objData["error"] = ""

                      }
                    }
                  }
                  payloadObjData[headerName.toLowerCase()] = cell === 'n/a' || cell === '' ? null : cell

                  break
                case "security_name":
                  objData["row_no"] = indexRow
                  objData["value"] = cell
                  objData["error"] = ''
                  payloadObjData[headerName.toLowerCase()] = cell === 'n/a' ? null : cell

                  break

                case "security_ticker":
                  let { resultST, errorST } = checkSecurityTickerConfermed(cell, transactionTypeLive)

                  if (resultST) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ''
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = errorST
                    takeRowData = true
                  }
                  payloadObjData["security"] = cell === 'n/a' || cell === '' ? null : cell

                  break

                case "transaction_type":
                  if (!cell) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = "transaction type is mandatory"
                    takeRowData = true
                  }
                  console.log(">>",transactionType.indexOf(cell),transactionTypeLive)
                  if (cell && transactionType.indexOf(cell) === -1) {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = "this transaction type is not allowed"
                    takeRowData = true
                  } else {
                    objData["row_no"] = indexRow
                    objData["value"] = cell
                    objData["error"] = ""

                  }
                  payloadObjData[headerName.toLowerCase()] = cell



                default:
                  break;
              }

              rowData.push(objData)

            }
            payload.push(payloadObjData)

            function toFindDuplicates(arry) {
              const seen = [];

              for (const object of arry) {
                if (seen.includes(JSON.stringify(object))) {
                  takeRowData = true
                  duplicateRowData = true
                } else {
                  seen.push(JSON.stringify(object));
                }
              }
            }

            toFindDuplicates(payload)
            if (takeRowData) {
              invalidDataTemp.push(rowData)
              if (duplicateRowData) {
                duplicateDataTemp.push(rowData)
              }
            }

            takeRowData = false
            duplicateRowData = false
          }

        }

        if (invalidDataTemp.length > 0) {

          setUploadMessage("Verification failed.")
          setInvalidData(invalidDataTemp)
          setDuplicateInfo(duplicateDataTemp)
          setHeaders(headers)
          setModalOpen(true)
        }
        else {
         
          console.log("payload=>", payload)
          setFinalPayload(payload)
          setUploadMessage("Verification success")
          setShowUploadBtn(true)
        }
      })

    }

    readCSV(f).then(e => {
      console.log(e)
      setTimeout(() => {
        setShowUploadBtn(false)

        formatDataAndValidate(e)
        setFile(null)
      }, 100);
    }).catch(e => {
      console.log(e)
    })

  }

  const bulkUpload = async (e) => {
    setUploadMessage("Uploading........")
    let url = `${process.env.REACT_APP_BACKEND_API}finance/positions/`;
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = { "Authorization": `Bearer ${accessToken}` }
    axios.post(url, finalPayload, { headers: headers }).then(response => {
      // console.log(response);
      console.log("false")
      alert.success("Position added successfully")
      setUploadMessage("Uploaded successfully")

    }).catch(error => {
      if (error?.response?.data?.duplicate_list) {
        setSecurityData("")
        // console.log(error?.response?.data?.duplicate_list)
        setDuplicateData(error?.response?.data?.duplicate_list)
        setDuplicate(true)
        setErrorMessage(error?.response?.data?.message)
        if (error?.response?.data?.security) {
          setSecurityData(error?.response?.data?.security)
        }
      } else {
        console.log(error)
        setUploadMessage(error?.response?.data?.message)
        setSecurityData("")
      }
      // alert.error(error?.response?.data?.message)

    })
  }
  const handleChange = (file) => {
    setFile(file)
    handleFileUpload([file])
  };
  return (
    <>

      <div style={{ display: modalOpen ? 'block' : 'none', width: "calc(100vw - 33px)", height: "90vh", position: "fixed", top: '10px', left: '10px', background: "#1E1E2F", borderRadius: "20px", zIndex: 100000, border: "1px solid white", overflowY: "auto" }}>
        <div style={{ cursor: "pointer", position: "fixed", top: '30px', right: '10px' }} onClick={e => { setModalOpen(false) }} className="mt-3 float-right mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div>
        <h1 className="mt-5 ml-4">Errors</h1>

        <Table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr >
              <th className="text-muted" style={{ width: '150px' }}>Row no</th>
              {headers.map(items => {
                return <th className="text-muted" style={{ width: '300px', padding: '0px' }}>{items}</th>
              })}

            </tr>
          </thead>
          <tbody>
            {invalidData.map((row, index1) => {
              return (
                <tr key={index1} >

                  <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row[0].row_no + 1}
                  {duplicateInfo[index1] && (
                    <>
                    <br />
                  <Badge color="danger" className='text-wrap text-left'>Duplicate Row</Badge>
                    </>
                  )}
                  
                  </td>
                  {row.map((cell, index) => {


                    return (
                      <>
                        <td key={index} style={{ width: '250px' }} className="w-30"  >{cell.value}<br /><Badge color="danger" className='text-wrap text-left'>{cell.error}</Badge></td>
                      </>
                    )

                  })}
                </tr>
              )
            })}


          </tbody>
        </Table>
      </div>
      {/* -----------------------------------------Second ------------------------------------------------------- */}
      <div style={{ display: duplicate ? 'block' : 'none', width: "calc(100vw - 33px)", height: "90vh", position: "fixed", top: '10px', left: '10px', background: "#1E1E2F", borderRadius: "20px", zIndex: 100000, border: "1px solid white", overflowY: "auto" }}>
        <div style={{ cursor: "pointer", position: "fixed", top: '30px', right: '10px' }} onClick={e => { setDuplicate(false) }} className="mt-3 float-right mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div>
        <h1 className="mt-5 ml-4">{errorMessage}</h1>

        <Table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr >
              <th className="text-muted" style={{ width: '150px' }}>Row no</th>
              <th className="text-muted" style={{ width: '150px' }}>	TRADE_DATE</th>
              <th className="text-muted" style={{ width: '150px' }}>	SETTLEMENT_DATE</th>
              <th className="text-muted" style={{ width: '150px' }}>BROKER_REFERENCE</th>
              <th className="text-muted" style={{ width: '209px' }}>TRANSACTION_DESCRIPTION</th>
              <th className="text-muted" style={{ width: '200px' }}>	UNIT_COST_LOCAL_CCY</th>
              <th className="text-muted" style={{ width: '150px' }}>QUANTITY</th>
              <th className="text-muted" style={{ width: '180px' }}>BROKERAGE_LOCAL_CCY</th>
              <th className="text-muted" style={{ width: '236px' }}>TRANSACTION_TAX_LOCAL_CCY</th>
              <th className="text-muted" style={{ width: '264px' }}>FX_TRANSACTION_COST_LOCAL_CCY</th>
              <th className="text-muted" style={{ width: '284px' }}>TOTAL_CONSIDERATION_LOCAL_CCY</th>
              <th className="text-muted" style={{ width: '150px' }}>TRIM_CODE</th>
              <th className="text-muted" style={{ width: '190px' }}>TRANSACTION_QUANTITY</th>
              <th className="text-muted" style={{ width: '150px' }}>TRANSACTION_TYPE</th>
              <th className="text-muted" style={{ width: '150px' }}>SECURITY_NAME</th>
              <th className="text-muted" style={{ width: '150px' }}>SECURITY_TICKER</th>
              <th className="text-muted" style={{ width: '150px' }}>ISIN</th>
              <th className="text-muted" style={{ width: '199px' }}>UNIT_COST_FOREIGN_CCY</th>
              <th className="text-muted" style={{ width: '192px' }}>BROKERAGE_FOREIGN_CCY</th>
              <th className="text-muted" style={{ width: '250px' }}>TRANSACTION_TAX_FOREIGN_CCY</th>
              <th className="text-muted" style={{ width: '290px' }}>FX_TRANSACTION_COST_FOREIGN_CCY</th>
              <th className="text-muted" style={{ width: '292px' }}>TOTAL_CONSIDERATION_FOREIGN_CCY</th>
              <th className="text-muted" style={{ width: '150px' }}>	FXRATE FLOAT</th>
              <th className="text-muted" style={{ width: '150px' }}>LOCAL_CCY</th>
              <th className="text-muted" style={{ width: '150px' }}>FOREIGN_CCY</th>


              {/* {headers.map(items => {
                return <th className="text-muted" style={{ width: '300px', padding: '0px' }}>{items}</th>
              })} */}

            </tr>
          </thead>
          <tbody>
            {/* {invalidData.map((row, index1) => {
              return (
                <tr key={index1} >

                  <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row[0].row_no + 1}</td>
                  {row.map((cell, index) => {


                    return (
                      <>
                        <td key={index} style={{ width: '250px' }} className="w-30"  >{cell.value}<br /><Badge color="danger" className='text-wrap text-left'>{cell.error}</Badge></td>
                      </>
                    )

                  })}
                </tr>
              )
            })} */}

            {duplicateData?.map((row, index1) => {
              return (
                <>
                  <tr key={index1} >

                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{index1 + 1}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.trade_date}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.settlement_date}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.broker_reference}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.transaction_description}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.unit_cost_local_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.quantity}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.brokerage_local_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.transaction_tax_local_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.fx_transaction_cost_local_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.total_consideration_local_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.trim_code}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.transaction_quantity}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.transaction_type}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" ></td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.security}<br />{securityData && <Badge color="danger" className='text-wrap1 text-left'>{securityData}</Badge>}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" ></td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.unit_cost_foreign_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.brokerage_foreign_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.transaction_tax_foreign_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.total_consideration_foreign_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.total_consideration_foreign_ccy}</td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" ></td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" ></td>
                    <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{row?.foreign_ccy}</td>

                  </tr>
                </>
              )
            })}

          </tbody>
        </Table>
      </div>
      {/* --------------------------------------------Second----------------------------------------------------- */}

      <div>

      </div>


      <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}}>

      <FileUploader handleChange={handleChange} fileOrFiles={file} id="file_uploader" classes="drop_area" multiple={false} name="file" types={fileTypes} />
      </div>
     
      <Card className="mt-3 py-5">

        <CardBody className="text-center">
          <p>{uploadMessage}</p>
          {showUploadBtn ? <Button onClick={e => {
            bulkUpload()
          }} className="btn btn-success">Upload</Button> : ''}

        </CardBody>
      </Card>

    </>
  )
}



export default PositionFileUploader