import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine-dark.css'; // Optional theme CSS
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS
// import 'ag-grid-community/styles/ag-theme-balhamdark.css'; // Optional theme CSS
import "../assets/css/live-position-view.css";
import {
  UncontrolledCollapse,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardSubtitle,
  Table,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import CustomToolTip from "components/CustomTooltip/CustomToolTip";

function LivePosition(props) {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const { getAccessTokenSilently } = useAuth0();
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [exchangeRate, setexchangeRate] = useState(["---", 0]);
  const [portfolioCCY, setPortfolioCCY] = React.useState();
  const [accessToken, setAccessToken] = useState("");
  const commaFormatter = Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const commaFormatterFull = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 9,
  });
  // Each Column Definition results in one Column.
  const customComparator = (valueA, valueB) => {
    let val1Number;
    let val2Number;
    console.log(valueA, valueB);

    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }

    val1Number = parseFloat(valueA.replace(/[^\d.-]/g, ""));

    val2Number = parseFloat(valueB.replace(/[^\d.-]/g, ""));

    return val1Number - val2Number;
  };
  const caseInsensitiveComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }
    if (valueA.toLowerCase() > valueB.toLowerCase()) {
      return 1;
    } else {
      return -1;
    }
  };
  const formatDate = (date) => {
    let newDate = new Date(date);
    var options = {
      hour12: false,
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    return newDate.toLocaleString("en-US", options);
  };

  const [masterColumnState, setMasterColumnState] = useState();
  // const [recList, setRecList] = useState([])

  const recList = useRef([]);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "Security name",
      comparator: caseInsensitiveComparator,
      filter: true,
      resizable: true,
      hide: false,
      sort: true,
      cellRenderer: "LinkComponent",
      cellClass: "my-class",
    },
    {
      field: "Security ticker",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
      cellRenderer: "LinkComponent",
    },
    {
      field: "Security ISIN",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
      cellRenderer: "LinkComponent",
    },
    {
      field: "Security currency",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
    },
    {
      field: "Holdings",
      type: "rightAligned",
      filter: true,
      resizable: true,
      hide: false,
      comparator: customComparator,
      tooltipField: "Holdings",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Holdings" },
    },
    {
      field: "holdings_full",
      type: "rightAligned",
      filter: true,
      resizable: true,
      hide: true,
    },
    {
      field: "Timestamp",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
    },
    {
      field: "Gmtoffset",
      type: "rightAligned",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Gmtoffset",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Gmtoffset" },
    },
    {
      field: "gmtoffset_full",
      type: "rightAligned",
      filter: true,
      customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Open",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Open",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Open" },
    },
    {
      field: "open_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "High",
      type: "rightAligned",
      filter: true,
      resizable: true,
      hide: false,
      comparator: customComparator,
      tooltipField: "High",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "High" },
    },
    {
      field: "high_full",
      type: "rightAligned",
      filter: true,
      resizable: true,
      hide: true,
    },
    {
      field: "Low",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Low",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Low" },
    },
    {
      field: "low_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Last tick",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Last tick",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Last tick" },
    },
    {
      field: "last_tick_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Volume",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Volume",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Volume" },
    },
    {
      field: "volume_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Previous close",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Previous close",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Previous close" },
    },
    {
      field: "previous_close_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Change",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Change",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Change" },
    },
    {
      field: "change_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Change p",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: false,
      tooltipField: "Change p",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Change p" },
    },
    {
      field: "change_p_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "Current valuation",
      type: "rightAligned",
      filter: true,
      resizable: true,
      hide: false,
      comparator: customComparator,
      tooltipField: "Current valuation",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "Current valuation" },
    },
    {
      field: "current_valuation_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
    {
      field: "P & L",
      type: "rightAligned",
      filter: true,
      resizable: true,
      hide: false,
      comparator: customComparator,
      tooltipField: "P & L",
      tooltipComponent: CustomToolTip,
      tooltipComponentParams: { color: "#ececec", field: "P & L" },
    },
    {
      field: "pnl_full",
      type: "rightAligned",
      filter: true,
      comparator: customComparator,
      resizable: true,
      hide: true,
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const hideShowColumn = (e) => {
    console.log(e.target.checked);
    const findRequireCol = (field) => {
      return field.field === e.target.value;
    };
    if (!e.target.checked) {
      let requireField = columnDefs.find(findRequireCol);
      columnDefs.forEach((value, index) => {
        if (value === requireField) {
          requireField.hide = true;
          columnDefs.splice(index, 1, requireField);
        }
      });
      gridRef.current.columnApi.setColumnsVisible([e.target.value], false);

      setColumnDefs([...columnDefs]);
    } else {
      let requireField = columnDefs.find(findRequireCol);
      columnDefs.forEach((value, index) => {
        if (value === requireField) {
          requireField.hide = false;
          columnDefs.splice(index, 1, requireField);
        }
      });
      gridRef.current.columnApi.setColumnsVisible([e.target.value], true);

      setColumnDefs([...columnDefs]);
    }
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const savedState = gridRef.current.columnApi.getColumnState();

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`,
        {
          order_json: JSON.stringify(columnDefs),
          visibility_json: JSON.stringify(savedState),
          page:"live_positions"
        },
        { headers: headers }
      )

      .then((res) => {
        
        console.log("TEST")
        console.log(res.data.order_json)

        if(res.data.order_json){
            setColumnDefs(JSON.parse(res.data.order_json))
        }
      });
  };

  function LinkComponent(props) {
    console.log("+++++====+++++",props)
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: props.value,
        }}
      ></p>
    );
  }
  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);
  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    // console.log('ClickEvent', event);
    if (event.colDef.field == "Security name") {
      const link = recList.current[event.rowIndex]?.security_urls?.[0]?.security_name_url;
      if (link) {
        window.open(link, "_blank");
      }
    }
    if (event.colDef.field == "Security ticker") {
      const link = recList.current[event.rowIndex]?.security_urls?.[0]?.security_ticker_url;
      if (link) {
        window.open(link, "_blank");
      }
    }
    if (event.colDef.field == "Security ISIN") {
      const link = recList.current[event.rowIndex]?.security_urls[0]?.security_isin_url;
      if (link) {
        window.open(link, "_blank");
      }
    }
  }, []);
  const onColumnMoved = (e) => {
    const savedState = gridRef.current.columnApi.getColumnState();
    let tempMasterColumnState = masterColumnState;
    console.log(masterColumnState);
    tempMasterColumnState["livePosition"] = savedState;
    setMasterColumnState(tempMasterColumnState);
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`,
        {
          order_json: JSON.stringify(columnDefs),
          visibility_json: JSON.stringify(tempMasterColumnState),
          page:'live_positions'
        },
        { headers: headers }
      )
      .then((res) => {});
  };
  // Example load data from sever
  useEffect(() => {
    const getLivePositions = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(accessToken);
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences?page=live_positions`,
          { headers: headers }
        )
        .then((res) => {
          console.log(">>lp table data", res.data);
          if (res.data.visibility_json) {
            setTimeout(() => {
              const savedState = JSON.parse(res.data.visibility_json);
              if (
                !savedState.livePosition &&
                !savedState.flatPosition &&
                !savedState.m2m
              ) {
                setMasterColumnState({ old_state: savedState });
              } else {
                console.log("--->>>", savedState);
                setMasterColumnState(savedState);

                gridRef.current.columnApi.applyColumnState({
                  state: savedState.livePosition,
                  applyOrder: true,
                });
              }
            }, 2000);
          }
          if (res.data.order_json) {
            setColumnDefs(JSON.parse(res.data.order_json));
          }
          console.log(JSON.parse(res.data.order_json))
        });

      axios
        .get(`${process.env.REACT_APP_BACKEND_API}finance/live_positions`, {
          headers: headers,
        })
        .then((rowData) => {
          let finalRowData = [];
          let portfolio_ccy = "";
          axios
            .get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, {
              headers: headers,
            })
            .then((response) => {
              portfolio_ccy = response.data.portfolio_ccy;
              setPortfolioCCY(portfolio_ccy);
              const formatterPortfolioCCY = new Intl.NumberFormat("en-US", {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: portfolio_ccy,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const formatterPortfolioCCYFull = new Intl.NumberFormat("en-US", {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: portfolio_ccy,
                minimumFractionDigits: 9,
              });
              if (rowData.data.live_positions.length > 0) {
                rowData.data.live_positions.forEach((value, index, array) => {
                  recList.current = [...recList.current, value];
                  finalRowData.push({
                    "Security name": value.security.name,
                    "Security ticker": value.security.ticker,
                    "Security ISIN": value.security.isin,
                    "Security currency": value.security.currency,
                    "id": value.id,
                    Holdings:
                      commaFormatter.format(value.net_sum) === "NaN"
                        ? ""
                        : commaFormatter.format(value.net_sum),
                    holdings_full:
                      commaFormatterFull.format(value.net_sum) === "NaN"
                        ? ""
                        : commaFormatterFull.format(value.net_sum),
                    Timestamp: value.pulled_price.timestamp
                      ? formatDate(value.pulled_price.timestamp)
                      : "",
                    Gmtoffset:
                      commaFormatter.format(value.pulled_price.gmtoffset) ===
                      "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.gmtoffset),
                    gmtoffset_full:
                      commaFormatterFull.format(
                        value.pulled_price.gmtoffset
                      ) === "NaN"
                        ? ""
                        : commaFormatterFull.format(
                            value.pulled_price.gmtoffset
                          ),
                    Open:
                      commaFormatter.format(value.pulled_price.open) === "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.open),
                    open_full:
                      commaFormatterFull.format(value.pulled_price.open) ===
                      "NaN"
                        ? ""
                        : commaFormatterFull.format(value.pulled_price.open),
                    High:
                      commaFormatter.format(value.pulled_price.high) === "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.high),
                    high_full:
                      commaFormatterFull.format(value.pulled_price.high) ===
                      "NaN"
                        ? ""
                        : commaFormatterFull.format(value.pulled_price.high),
                    Low:
                      commaFormatter.format(value.pulled_price.low) === "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.low),
                    low_full:
                      commaFormatterFull.format(value.pulled_price.low) ===
                      "NaN"
                        ? ""
                        : commaFormatterFull.format(value.pulled_price.low),
                    "Last tick":
                      commaFormatter.format(value.pulled_price.last_tick) ===
                      "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.last_tick),
                    last_tick_full:
                      commaFormatterFull.format(
                        value.pulled_price.last_tick
                      ) === "NaN"
                        ? ""
                        : commaFormatterFull.format(
                            value.pulled_price.last_tick
                          ),
                    Volume:
                      commaFormatter.format(value.pulled_price.volume) === "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.volume),
                    volume_full:
                      commaFormatterFull.format(value.pulled_price.volume) ===
                      "NaN"
                        ? ""
                        : commaFormatterFull.format(value.pulled_price.volume),
                    "Previous close":
                      commaFormatter.format(
                        value.pulled_price.previousclose
                      ) === "NaN"
                        ? ""
                        : commaFormatter.format(
                            value.pulled_price.previousclose
                          ),
                    previous_close_full:
                      commaFormatterFull.format(
                        value.pulled_price.previousclose
                      ) === "NaN"
                        ? ""
                        : commaFormatterFull.format(
                            value.pulled_price.previousclose
                          ),
                    Change:
                      commaFormatter.format(value.pulled_price.changed) ===
                      "NaN"
                        ? 0
                        : commaFormatter.format(value.pulled_price.changed),
                    change_full:
                      commaFormatterFull.format(value.pulled_price.changed) ===
                      "NaN"
                        ? 0
                        : commaFormatterFull.format(value.pulled_price.changed),
                    "Change p":
                      commaFormatter.format(value.pulled_price.change_p) ===
                      "NaN"
                        ? ""
                        : commaFormatter.format(value.pulled_price.change_p),
                    change_p_full:
                      commaFormatterFull.format(value.pulled_price.change_p) ===
                      "NaN"
                        ? ""
                        : commaFormatterFull.format(
                            value.pulled_price.change_p
                          ),
                    "Current valuation":
                      formatterPortfolioCCY.format(value.current_valuation) ===
                      "NaN"
                        ? ""
                        : formatterPortfolioCCY.format(value.current_valuation),
                    current_valuation_full:
                      formatterPortfolioCCYFull.format(
                        value.current_valuation
                      ) === "NaN"
                        ? ""
                        : formatterPortfolioCCYFull.format(
                            value.current_valuation
                          ),
                    // 'Current valuation': value.current_valuation,
                    "P & L":
                      formatterPortfolioCCY.format(value.pnl) === "NaN"
                        ? ""
                        : formatterPortfolioCCY.format(value.pnl),
                    pnl_full:
                      formatterPortfolioCCYFull.format(value.pnl) === "NaN"
                        ? ""
                        : formatterPortfolioCCYFull.format(value.pnl),
                  });
                });
              }
              console.log(finalRowData);
              setRowData(finalRowData);
              setTimeout(() => {
                autoSizeAll(false)
                
              }, 200);
              setexchangeRate(rowData.data.fx_rates);
            });
        });
    };
    getLivePositions();
  }, []);

  // Example using Grid's API
  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);

  const postSortRows = params => {
    let rowNodes = params.nodes;
    
    let recListData = recList?.current
    
    let filtered_data = []
    for (let index = 0; index < rowNodes?.length; index++) {
      let filtered = recListData?.find((item) => item?.id === rowNodes?.[index]?.data?.id)
      filtered_data.push(filtered)
    }
    recList.current = filtered_data
  };

  return (
    <div className="content">
      <Card>
        <CardBody>
          <CardSubtitle className="mb-2 text-primary"></CardSubtitle>
          <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
            <Card>
              <CardBody>
                <Table className="">
                  <tbody>
                    {exchangeRate.length > 0
                      ? exchangeRate.map((item, index) => {
                          return (
                            <tr key={index + Math.random(index) * 100}>
                              <td
                                className="text-left"
                                key={index + Math.random(index) * 100}
                              >
                                {item.currency_pair}
                              </td>

                              <td className="text-left" key={index + 1}>
                                {item.last_tick}
                              </td>
                            </tr>
                          );
                        })
                      : "Not found"}
                  </tbody>
                </Table>{" "}
              </CardBody>
            </Card>
          </UncontrolledCollapse>
          <UncontrolledCollapse toggler="#exchangeRateToggler">
            <Card>
              <CardBody>
                <div className="row">
                  {columnDefs.map((value, index, array) => {
                    if (value.field.indexOf("_") === -1) {
                      return (
                        <div
                          className="col-2"
                          key={index + Math.random(index) * 100}
                        >
                          <FormGroup
                            key={index + Math.random(index) * 100}
                            check
                          >
                            <Label key={index + Math.random(index) * 100} check>
                              <Input
                                type="checkbox"
                                value={value.field}
                                checked={!value.hide}
                                onChange={(e) => {
                                  hideShowColumn(e);
                                }}
                                key={index + Math.random(index) * 100}
                              />{" "}
                              <span
                                key={index + Math.random(index) * 100}
                                className="form-check-sign"
                              >
                                <span
                                  className="check"
                                  key={index + Math.random(index) * 100}
                                >
                                  {" "}
                                  {value.field}{" "}
                                </span>
                              </span>
                            </Label>
                          </FormGroup>
                        </div>
                      );
                    }
                  })}
                </div>{" "}
              </CardBody>{" "}
            </Card>{" "}
          </UncontrolledCollapse>
          {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
          <div
            className="ag-theme-material mt-3 live-positions-records"
            style={{ height: "80vh" }}
          >
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API
              rowData={rowData} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              tooltipShowDelay={0}
              postSortRows={postSortRows}
              tooltipHideDelay={2000}
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              rowGroupPanelShow={"always"}
              pivotPanelShow={"always"}
              onCellClicked={cellClickedListener}
              onDragStopped={onColumnMoved} // Optional - registering for Grid Event
              rowHeight={25}
            headerHeight={35}
            />{" "}
          </div>{" "}
        </CardBody>{" "}
      </Card>
    </div>
  );
}

export default LivePosition;
