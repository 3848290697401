import { configureStore } from '@reduxjs/toolkit'
import portfolioCCYModal from '../features/portfolioCCYModal/portfolioCCYModalSlice'
import dividendSplitsTable from 'features/DividendSplitsTable/DividendSplitsTableSlice'


export const store = configureStore({
  reducer: {
    portfolioCCYModal: portfolioCCYModal,
    dividendSplitsTable:dividendSplitsTable
  },
})