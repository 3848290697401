import axios from 'axios';
import { showModal } from 'features/portfolioCCYModal/portfolioCCYModalSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Button, Modal } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react";
const isAuthenticated = true; // Replace with your authentication logic

function PrivateRoute({ children, ...rest }) {

  const { getAccessTokenSilently } = useAuth0();

  console.log("Hello")
  let dispatch = useDispatch()

  useEffect(async () => {


    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });

    let headers = {
      'Authorization': `Bearer ${accessToken}`
    }

    await axios.get(`${process.env.REACT_APP_BACKEND_API}finance/check-portfolioccy/`, { headers: headers }).then(response => {
      console.log("=====><=======",response)
      if (!response.data.portfolio_ccy){
        dispatch(showModal())
      }
    })

  }, [])




  return (
    <Route
      {...rest}
      render={() => {

        return isAuthenticated === true ? (
          children
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

export default PrivateRoute;
