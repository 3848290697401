import React, { useEffect } from 'react'
import { Card, CardTitle, CardHeader, CardBody, Row, Col, Table } from 'reactstrap';
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash"
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSplitsTrue,setSelectedSplitSecurity } from 'features/DividendSplitsTable/DividendSplitsTableSlice';
import SplitsTable from 'components/SplitsTable/SplitsTable';




function SplitsView(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [optionSecurities, SetOptionSecurities] = React.useState([])
  const splitsTableFetched = useSelector((state) => state.dividendSplitsTable.fetchSplits);
  const selectedSplitsSecurity = useSelector((state) => state.dividendSplitsTable.selectedSplitSecurity);
  const [selectedSecurity, SetSelectedSecurity] = React.useState("")


  const dispatch = useDispatch()
  const customComparator = (valueA, valueB) => {
    let val1Number;
    let val2Number;
    console.log(valueA, valueB);

    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }

    val1Number = parseFloat(valueA.replace(/[^\d.-]/g, ""));

    val2Number = parseFloat(valueB.replace(/[^\d.-]/g, ""));

    return val1Number - val2Number;
  };
  const renderShowAllButton = (params) => {
    return (
      <Link to={`/my-data/${params.data.transaction_type}`}>
        <button className='btn btn-secondary btn-sm'>
          Show All
        </button>
      </Link>
    );
  };

  
  const getTickers = async () => {

    const domain = "mark-to-market.eu.auth0.com";

    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });

    let url = `${process.env.REACT_APP_BACKEND_API}finance/get-net-securities/`

    let headers = {
      "Authorization": `Bearer ${accessToken}`
    }


    axios.get(url, { headers: headers }).then(response => {
      console.log("---------------------------------", response)
      let tempData=response.data.data
      SetOptionSecurities([...tempData])
    })
  }
  useEffect(() => {
    getTickers()
  } ,[window.location.pathname])
  
  return (
    <div className="content">

      <Row>
        <Col md="12">
          <Card className="card-chart">
            {!splitsTableFetched ? (
              <>

                <CardHeader>
                  <CardTitle>
                    Splits
                  </CardTitle>
                </CardHeader>
                <CardBody className='mx-2'>

                  <h4 className='my-0 mb-2'>Select security :</h4>
                  <div>

                    <select class="btn-sm" style={{ width: "50%" }} aria-label="Default select example" onChange={e => { dispatch(setSelectedSplitSecurity({security:e.target.value})) }}>
                      <option selected>Open this select menu</option>
                      {optionSecurities.map(item => (
                        <option value={item} >{item}</option>
                      ))}
                    </select>
                  </div>
                  <button className='btn btn-secondary mt-4 btn-sm' onClick={async e => {
                    const domain = "mark-to-market.eu.auth0.com";

                    const accessToken = await getAccessTokenSilently({
                      audience: `https://${domain}/api/v2/`,
                      scope: "read:current_user",
                    });

                    let url = `${process.env.REACT_APP_BACKEND_API}finance/get-splits/`

                    let headers = {
                      "Authorization": `Bearer ${accessToken}`
                    }


                    axios.get(url, { params: { security: selectedSplitsSecurity }, headers: headers }).then(response => {
                      console.log("--------------------------------->", response)
                      // SetOptionSecurities(response.data.data)
                      dispatch(fetchSplitsTrue({ splitsData: response.data.data }))
                    })

                  }}> Get Splits</button>
                </CardBody>
              </>
            )
            
              : (<SplitsTable selectedSecurity={selectedSecurity} optionSecurities={optionSecurities} />)


            }

          </Card>

        </Col>

      </Row>
    </div>
  )
}

export default SplitsView