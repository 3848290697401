import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSplitsTrue } from 'features/DividendSplitsTable/DividendSplitsTableSlice';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { setSelectedSplitSecurity } from 'features/DividendSplitsTable/DividendSplitsTableSlice';

const SplitsTable = (props) => {
  const [optionSecurities, SetOptionSecurities] = React.useState([])
  const splitsData = useSelector((state) => state.dividendSplitsTable.splitsData);
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0();
  const selectedSplitsSecurity = useSelector((state) => state.dividendSplitsTable.selectedSplitSecurity);
  useEffect(() => {
    
    SetOptionSecurities([...props.optionSecurities])
  }, [props.optionSecurities])
  
console.log("---->>>>",selectedSplitsSecurity)
  const columnDefs = [
    { headerName: 'Split Date', field: 'splitDate' },
    { headerName: 'Stock', field: 'stock' },
    { headerName: 'Split Ratio', field: 'splitRatio' },
    { headerName: 'Transaction Date', field: 'yourPosition' },
    { headerName: 'Transaction', field: 'transaction' },
    { headerName: 'No. of shares', field: 'no_of_shares' },
  ];

  const rowData = [
    // sample data for demonstration
    { dividendDate: '2021-01-01', stock: 'ABC', dividendAmount: 10, yourPosition: 100, transaction: 'Buy', amount: 500, netPosition: 200, dividendAmount2: 20 },
    { dividendDate: '2021-02-01', stock: 'XYZ', dividendAmount: 5, yourPosition: 50, transaction: 'Sell', amount: 300, netPosition: 150, dividendAmount2: 15 },
    // add more                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  rows as needed
  ];

  
  return (
    <>
      <div className='mt-2' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <h4 className='m-2'>Splits</h4>
        <h5 className='text-primary'>*Please update your stock data according to the splits</h5>
        <select className="btn-sm mr-3" style={{ width: "300px" }} aria-label="Default select example" onChange={async e => {
          
          dispatch(setSelectedSplitSecurity({security:e.target.value}))
          const domain = "mark-to-market.eu.auth0.com";

          const accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });

          let url = `${process.env.REACT_APP_BACKEND_API}finance/get-splits/`

          let headers = {
            "Authorization": `Bearer ${accessToken}`
          }
          axios.get(url, { params: { security: e.target.value }, headers: headers }).then(response => {
                      console.log("--------------------------------->", response)
                      // SetOptionSecurities(response.data.data)
                      dispatch(fetchSplitsTrue({ splitsData: response.data.data }))
                    })
          }}>
          
          {optionSecurities.map(item => (
           item==selectedSplitsSecurity? <option selected value={item} >{item}</option> : <option value={item} >{item}</option>
          ))}
        </select>
      </div>
      <div className="ag-theme-material mt-3" style={{ height: '75vh' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={splitsData}
          rowHeight={25}
            headerHeight={35}
        />
      </div>
    </>
  );
};

export default SplitsTable;
