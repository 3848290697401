import React, { useMemo } from 'react';


function CustomToolTip(props) {
    const data = useMemo(
       
        () => {
            try {
                
                return  props.api.getDisplayedRowAtIndex(props.rowIndex).data
            } catch (error) {
                return "444"
            }
        
        },
        []
    );
    console.log(props)
    switch (props.field) {
        case "Holdings":
            return (
                <div
                    className="custom-tooltip"
                    style={{ backgroundColor: props.color || 'white', color: "black" }}
                >
                    <p style={{ color: 'black', padding: '10px' }}>
                        <span>{props.field}: </span> {data.holdings_full}
                    </p>

                </div>
            );
        case "Gmtoffset":
                return (
                    <div
                        className="custom-tooltip"
                        style={{ backgroundColor: props.color || 'white', color: "black" }}
                    >
                        <p style={{ color: 'black', padding: '10px' }}>
                            <span>{props.field}: </span> {data.gmtoffset_full}
                        </p>
                    </div>
                );
        case "Open":
                    return (
                        <div
                            className="custom-tooltip"
                            style={{ backgroundColor: props.color || 'white', color: "black" }}
                        >
        
                            <p style={{ color: 'black', padding: '10px' }}>
                                <span>{props.field}: </span> {data.open_full}
                            </p>
        
                        </div>
                    );
        case "High":
                        return (
                            <div
                                className="custom-tooltip"
                                style={{ backgroundColor: props.color || 'white', color: "black" }}
                            >
            
                                <p style={{ color: 'black', padding: '10px' }}>
                                    <span>{props.field}: </span> {data.high_full}
                                </p>
            
                            </div>
                        );
        case "Low":
                            return (
                                <div
                                    className="custom-tooltip"
                                    style={{ backgroundColor: props.color || 'white', color: "black" }}
                                >
                
                                    <p style={{ color: 'black', padding: '10px' }}>
                                        <span>{props.field}: </span> {data.low_full}
                                    </p>
                
                                </div>
                            );
        case "Last tick":
                                return (
                                    <div
                                        className="custom-tooltip"
                                        style={{ backgroundColor: props.color || 'white', color: "black" }}
                                    >
                    
                                        <p style={{ color: 'black', padding: '10px' }}>
                                            <span>{props.field}: </span> {data.last_tick_full}
                                        </p>
                    
                                    </div>
                                );
        case "Volume":
                                    return (
                                        <div
                                            className="custom-tooltip"
                                            style={{ backgroundColor: props.color || 'white', color: "black" }}
                                        >
                        
                                            <p style={{ color: 'black', padding: '10px' }}>
                                                <span>{props.field}: </span> {data.volume_full}
                                            </p>
                        
                                        </div>
                                    );
        case "Previous close":
                                        return (
                                            <div
                                                className="custom-tooltip"
                                                style={{ backgroundColor: props.color || 'white', color: "black" }}
                                            >
                            
                                                <p style={{ color: 'black', padding: '10px' }}>
                                                    <span>{props.field}: </span> {data.previous_close_full}
                                                </p>
                            
                                            </div>
                                        );
        case "Change":
                                            return (
                                                <div
                                                    className="custom-tooltip"
                                                    style={{ backgroundColor: props.color || 'white', color: "black" }}
                                                >
                                
                                                    <p style={{ color: 'black', padding: '10px' }}>
                                                        <span>{props.field}: </span> {data.change_full}
                                                    </p>
                                
                                                </div>
                                            );
        case "Change p":
                                                return (
                                                    <div
                                                        className="custom-tooltip"
                                                        style={{ backgroundColor: props.color || 'white', color: "black" }}
                                                    >
                                    
                                                        <p style={{ color: 'black', padding: '10px' }}>
                                                            <span>{props.field}: </span> {data.change_p_full}
                                                        </p>
                                    
                                                    </div>
                                                );
        case "M2M price":
            return (
                <div
                    className="custom-tooltip"
                    style={{ backgroundColor: props.color || 'white', color: "black" }}
                >

                    <p style={{ color: 'black', padding: '10px' }}>
                        <span>{props.field}: </span> {data.m2m_price_full || props.column.colDef.headerTooltip}
                    </p>

                </div>
            );
        case "Current valuation":
            return (
                <div
                    className="custom-tooltip"
                    style={{ backgroundColor: props.color || 'white', color: "black" }}
                >

                    <p style={{ color: 'black', padding: '10px' }}>
                        <span>{props.field}: </span> {data.current_valuation_full}
                    </p>

                </div>
            );
        case "P & L":
            return (
                <div
                    className="custom-tooltip"
                    style={{ backgroundColor: props.color || 'white', color: "black" }}
                >

                    <p style={{ color: 'black', padding: '10px' }}>
                        <span>{props.field}: </span> {data.pnl_full}
                    </p>

                </div>
            );

        case "Realized P & L":
                return (
                    <div
                        className="custom-tooltip"
                        style={{ backgroundColor: props.color || 'white', color: "black" }}
                    >
    
                        <p style={{ color: 'black', padding: '10px' }}>
                            <span>{props.field}: </span> {data.realized_pnl}
                        </p>
    
                    </div>
                );
        default:
            return (
                <div
                    className="custom-tooltip"
                    style={{ backgroundColor:'grey', color: "black" }}
                >

                    <p style={{ color: 'green', padding: '10px' }}>
                        <span>{props.field}: </span> {data.realized_pnl}
                    </p>

                </div>
            );

    }

}

export default CustomToolTip