/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import HomeContext from 'context';

// reactstrap components

import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalBody,
  Badge,
  Table,
  UncontrolledTooltip,
  Input,
  Label
} from "reactstrap";
import LogoutButton from "components/LogoutButton/LogoutButton";
import axios from "axios";
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';

import { Link } from "react-router-dom/cjs/react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import AutoSuggest from "components/Autosuggest/Autosuggest";
import { useSelector } from "react-redux";
let style = {
  marginLeft: "15px"
}

function AdminNavbar(props) {

  let { dispatch, portfolio_ccy, is_mydata_fetched, transaction_type_filter, trade_date_filter } = React.useContext(HomeContext)
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [profilePic, setprofilePic] = React.useState(props.userImage);
  const [loader, setLoader] = React.useState(false);
  const [availableCCY, setAvailableCCY] = React.useState([])
  const [modalOpen, setModalOpen] = React.useState(false)
  const [headers, setHeaders] = React.useState([])
  const [invalidData, setInvalidData] = React.useState([])
  const [disableUpload, setDisableUpload] = React.useState(false)
  const inputRef = React.useRef(null);
  const [portfolioCCY, setPortfolioCCY] = React.useState()
  const alert = useAlert()
  const { getAccessTokenSilently } = useAuth0();
  const isShowPortfolioCCYModal = useSelector((state) => state.portfolioCCYModal.isShow);
  const FileDownload = require('js-file-download');
  React.useEffect(() => {
    const getAvailableCCY = async () => {

      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
        'Authorization': `Bearer ${accessToken}`

      }
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_currencies`, { headers: headers })
        .then(response1 => {
          setAvailableCCY(response1.data)
          axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, { headers: headers })
            .then(response2 => {
              console.log('>> >> >>', response2)
              setPortfolioCCY(response2.data.portfolio_ccy)

            })

        })
    }
    getAvailableCCY()
  }, []);
  const getLastPrice = async () => {
    const formatDate = (date) => {
      let newDate = new Date(date);
      var options = { hour12: false, month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
      return newDate.toLocaleString("en-US", options)
    }

    const commaFormatter = Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    const commaFormatterFull = Intl.NumberFormat('en-US', {
      minimumFractionDigits: 9,
    })
    const domain = "mark-to-market.eu.auth0.com";

    let url = `${process.env.REACT_APP_BACKEND_API}finance/live_positions?get_live_prices=1&is_m2m=1`
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = {
      'Authorization': `Bearer ${accessToken}`
    }
    console.log("accessToken", accessToken)
    setLoader(true)


    axios.get(url, { headers: headers }).then(rowData => {
      console.log(">>>>>", rowData)
      let finalRowData = []
      if (rowData.data.live_positions.length > 0) {
        const formatterPortfolioCCY = new Intl.NumberFormat('en-US', {
          style: 'currency', currencyDisplay: 'narrowSymbol',
          currency: portfolio_ccy,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2

        });
        const formatterPortfolioCCYFull = new Intl.NumberFormat('en-US', {
          style: 'currency', currencyDisplay: 'narrowSymbol',
          currency: portfolio_ccy,
          minimumFractionDigits: 9,

        });
        let fx_rate = ""
        let formatterSecurityCCY
        let formatterSecurityCCYFull
        rowData.data.live_positions.forEach((value, index, array) => {
          formatterSecurityCCY = new Intl.NumberFormat('en-US', {
            style: 'currency', currencyDisplay: 'narrowSymbol',
            currency: value.security.currency === 'GBX' ? 'GBP' : value.security.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,

          });
          formatterSecurityCCYFull = new Intl.NumberFormat('en-US', {
            style: 'currency', currencyDisplay: 'narrowSymbol',
            currency: value.security.currency === 'GBX' ? 'GBP' : value.security.currency,
            minimumFractionDigits: 9,

          });

          rowData.data.fx_rates.forEach(fxrate_obj => {

            if (fxrate_obj.currency_pair.slice(0, 3) === value.security.currency) {
              fx_rate = fxrate_obj.last_tick
            } else {
              fx_rate = 1
            }
          })

          finalRowData.push({
            'Security name': value.security.name,
            'Security ticker': value.security.ticker,
            'Security ISIN': value.security.isin,
            'Security currency': value.security.currency,
            'Holdings': commaFormatter.format(parseFloat(value.net_sum)),
            'M2M price last updated': formatDate(value.last_updated_m2m_price),
            'M2M price *': formatterSecurityCCY.format(value.m2m_price),
            'Current valuation': formatterPortfolioCCY.format(value.current_valuation),
            'P & L': formatterPortfolioCCY.format(value.pnl),
            'fx_rate': fx_rate,
            'denominator': value.security.denominator,
            'cost_of_current_holdings': value.cost_of_current_holdings,
            'id': value.id,
            'portfolio_ccy': portfolio_ccy,
            'holdings_full': commaFormatterFull.format(parseFloat(value.net_sum)),
            'm2m_price_full': formatterSecurityCCYFull.format(value.m2m_price),
            'current_valuation_full': formatterPortfolioCCYFull.format(value.current_valuation),
            'pnl_full': formatterPortfolioCCYFull.format(value.pnl),
          })
        })
      }
      alert.success("Prices updated successfully")
      console.log("FINAL ROW DATA LIVE", finalRowData)
      dispatch({ type: "UPDATE_M2M_DATA", payload: { m2m_data: finalRowData } })

      setLoader(false)


    })
  }
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  const downloadPostions = async e => {
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = { 'Authorization': `Bearer ${accessToken}` }


    let url = `${process.env.REACT_APP_BACKEND_API}finance/download_positions`
    axios.get(url, { headers: headers, responseType: 'blob' }).then(response => {
      console.log(response)



      FileDownload(response.data, 'report.csv');
    })
  }
  const deleteData = async e => {
    e.target.innerText = "Please wait.."
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = { 'Authorization': `Bearer ${accessToken}` }
    let url = `${process.env.REACT_APP_BACKEND_API}finance/delete_positions`
    axios.delete(url, { headers: headers }).then(response => {
      toggleModalSearch()
      alert.success("Your data deleted successfully, please refresh the page to update.")

    })
  }

  async function applyFilter() {
    document.getElementById("btnApplyChanges").innerText = "Please wait..."

    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios.get(`${process.env.REACT_APP_BACKEND_API}finance/position/filter?transaction-type=${transaction_type_filter}&tradedate=${trade_date_filter}&from-tradedate=${""}&to-tradedate=${""}`, { headers: headers })
      .then(response => {
        let finalRowData = []
        if (response.data.positions.length > 0) {
          response.data.positions.forEach((value, index, array) => {
            finalRowData.push({
              "Trade date": value.trade_date,
              "Settlement date": value.settlement_date,
              "Broker refrence": value.broker_refrence,
              "Transaction description": value.transaction_description,
              "Transaction quantity": value.transaction_quantity,
              "Unit cost local ccy": value.unit_cost_local_ccy,
              "Quantity": value.quantity,
              "Brokerage local cyy": value.brokerage_local_ccy,
              "Transaction tax local ccy": value.transaction_tax_local_ccy,
              "Fx transaction cost local ccy": value.fx_transaction_cost_local_ccy,
              "Total consideration local ccy": value.total_consideration_local_ccy,
              "Trim code": value.trim_code,
              "Transaction type": value.transaction_type,
              "Security name": value.security_name,
              "Security ticker": value.security_ticker,
              "ISIN": value.isin,
              "Unit cost foreign ccy": value.unit_cost_foreign_ccy,
              "Brokerage foreign ccy": value.brokerage_foreign_ccy,
              "Transaction tax foreign ccy": value.transaction_tax_foreign_ccy,
              "Fx transaction cost foreign ccy": value.fx_transaction_cost_foreign_ccy,
              "Total consideration foreign ccy": value.total_consideration_foreign_ccy,
              "Fx rate": value.fx_rate,
              "Local ccy": value.local_ccy,
              "Foreign ccy": value.foreign_ccy,

            })
          })

        }
        dispatch({ type: "UPDATE_POSITION", payload: { positions: finalRowData } })
        document.getElementById("btnApplyChanges").innerText = "Updated"

        setTimeout(() => {
          document.getElementById("btnApplyChanges").innerText = "Apply changes"

        }, 2000)

      })
  }

  const downloadWebLinks = async e => {
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = { 'Authorization': `Bearer ${accessToken}` }


    let url = `${process.env.REACT_APP_BACKEND_API}finance/download_web_links`
    axios.get(url, { headers: headers, responseType: 'blob' }).then(response => {



      FileDownload(response.data, 'report.csv');
    })
  }

  const handleChange = (e) => {
    const inputFile = e.target.files[0]
    uploadWebLinks(inputFile)
  };

  const uploadWebLinks = async (file) => {
    const formData = new FormData()

    formData.append('file', file)

    setDisableUpload(true)

    let url = `${process.env.REACT_APP_BACKEND_API}finance/web_links/`;
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = { "Authorization": `Bearer ${accessToken}` }
    axios.post(url, formData, { headers: headers },).then(response => {
      setDisableUpload(false)

      if (!response?.data?.wrong_ticker?.[1]) {
        alert.success("Web links added successfully, please refresh the page to update.")
      } else {
        let data = response?.data?.wrong_ticker?.[0]

        let errorData = response?.data?.wrong_ticker
        errorData.splice(0, 1)

        function replaceEmptyWithNA(arr) {
          return arr.map(value => {
            if (Array.isArray(value)) {
              return replaceEmptyWithNA(value);
            } else if (!value) {
              return "N/A";
            } else {
              return value;
            }
          });
        }

        const newArr = replaceEmptyWithNA(errorData);

        // setInvalidData()
        return new Promise((resolve, reject) => {
          // console.log(data)
          let headers = data

          setInvalidData(newArr)
          setHeaders(headers)
          setModalOpen(true)
        })
      }

    }).catch(error => {
      let errorMessage = error?.response?.data?.message ? error?.response?.data?.message : error?.response?.message
      alert.error(errorMessage)
      setDisableUpload(false)

    })
  }

  const handleFileButtonClick = () => {
    inputRef.current.click();
  }

  return (
    <>

      <div style={{ display: modalOpen ? 'block' : 'none', width: "calc(100vw - 33px)", height: "90vh", position: "fixed", top: '10px', left: '10px', background: "#1E1E2F", borderRadius: "20px", zIndex: 100000, border: "1px solid white", overflowY: "auto" }}>
        <div style={{ cursor: "pointer", position: "fixed", top: '30px', right: '10px' }} onClick={e => { setModalOpen(false) }} className="mt-3 float-right mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div>
        <h1 className="mt-5 ml-4">Errors</h1>

        <Table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr >
              <th className="text-muted" style={{ width: '150px' }}>Row no</th>
              {headers.map(items => {
                return <th className="text-muted" style={{ width: '300px', padding: '0px' }}>{items}</th>
              })}

            </tr>
          </thead>
          <tbody>
            {invalidData.map((row, index1) => {
              return (
                <tr key={index1} >

                  <td scope="row" style={{ width: '250px', padding: "0 20px" }} className="text-danger text-left" >{index1 + 1}
                    <br />{/* {duplicateInfo[index1] && (
                    <>
                    <br /> */}
                    <Badge color="danger" className='text-wrap text-left'>Invalid Ticker</Badge>
                    {/* </>
                  )} */}

                  </td>

                  {row.map((cell, index) => {


                    return (
                      <>
                        <td key={index} style={{ width: '250px' }} className="w-30"  >{cell}<br /></td>
                      </>
                    )

                  })}
                </tr>
              )
            })}


          </tbody>
        </Table>
      </div>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand style={props.pageName == "Live Position" || props.pageName == "Mark to Market" || props.pageName == "My Data" ? { display: "flex", width: "calc(100% - 310px)", justifyContent: "space-between" } : {}} href="#pablo" onClick={(e) => e.preventDefault()}>
              <span style={{ display: "flex", justifyContent: "space-between", width: "500px" }}>
                <span style={{ width: "50%" }}>

                  {props.pageName}
                </span>
                <span style={{ width: "50%" }}>
                  {props.pageName === "Mark to Market" || props.pageName === "Flat Positions" ? <>

                    <span className="text-info">Total P n L :</span>
                    <span className={props.text_color}>{props.total_pnl}</span>
                  </> : ""}
                </span>
              </span>

              {/* {props.pageName}{props.pageName == "Mark to Market" || props.pageName == "Flat Positions" ? <span style={props.pageName == 'Flat Positions' ? { marginLeft: "40%",display:"inline" } : { marginLeft: "30%" }} className={props.text_color}><span className="text-info">Total P & L : </span>{props.total_pnl}</span>: ""} */}
              <span>
                {props.pageName == "Live Position" ?
                  (<>
                    <Button color="secondary"
                      className="btn-sm"
                      href="#collapseExample"
                      id="exchangeRateToggler" >
                      Hide / Show Column </Button>

                    <Button color="secondary"
                      className="btn-sm"
                      href="#collapseExample"
                      id="linkToggler" >
                      View Exchange Rates
                    </Button>
                  </>)
                  : ""}
              </span>

              {/* {props.pageName == "My Data"?
                (<>
                  <Button color="secondary"
                    className="btn-sm"
                    href="#collapseExample1"
                    id="exchangeRateToggler1" >
                    Hide / Show Column </Button>
                </>
                ):(<></>)} */}
              {props.pageName == "My Data" && is_mydata_fetched ?
                (<span style={{ display: "flex", alignItems: "stretched" }}>
                  <div>
                    <select value={transaction_type_filter} onChange={e => {
                      dispatch({ type: "UPDATE_TRANSACTION_TYPE_FILTER", payload: { transaction_type_filter: e.target.value } })
                      document.getElementById("changeApplyBtn").style.display = ""
                    }} className="btn-sm" name="cars" id="cars">
                      <option value="volvo bg-light">Select Transaction Types</option>

                      <option value="Accounting Transfer">Accounting Transfer</option>
                      <option value="Buy">Buy</option>
                      <option value="Closing Balance">Closing Balance</option>
                      <option value="Deposit">Deposit</option>
                      <option value="Dividend - Equity">Dividend - Equity</option>
                      <option value="Dividend - Fund">Dividend - Fund</option>
                      <option value="Dividend - Loyalty Fund">Dividend - Loyalty Fund</option>
                      <option value="Dividend - Overseas">Dividend - Overseas</option>
                      <option value="Fund Rebate">Fund Rebate</option>
                      <option value="Interest">Interest</option>
                      <option value="LOYALTYU">LOYALTYU</option>
                      <option value="Management Fees">Management Fees</option>
                      <option value="Sell">Sell</option>
                      <option value="Transfer from income account">Transfer from income account</option>
                      <option value="Transfer in capital account">Transfer in capital account</option>
                      <option value="Other in">Other in</option>
                      <option value="Other out">Other out</option>
                      <option value="Unit Rebate">Unit Rebate</option>
                      <option value="all">All</option>

                    </select>
                  </div>

                  <div className="ml-4">
                    <select value={trade_date_filter} onChange={e => {
                      dispatch({ type: "UPDATE_TRADE_DATE_FILTER", payload: { trade_date_filter: e.target.value } })
                      console.log(">>", trade_date_filter)
                      document.getElementById("changeApplyBtn").style.display = ""
                    }} className="btn-sm" >
                      <option value="last-week">Last Week</option>
                      <option value="last-month">Last Month</option>
                      <option value="last-year">Last year</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                  <Button color="secondary"
                    className="btn-sm"
                    href="#filterSecurity"
                    id="filterSecurityToggler" >
                    Filter by security </Button>
                  <Button color="secondary"
                    className="btn-sm"
                    href="#hideShowColumn"
                    id="hideShowColumnTrigger" >
                    Hide/Show Col </Button>
                  <div className="ml-3" id="changeApplyBtn" style={{ display: "none" }}>
                    <button onClick={applyFilter} id="btnApplyChanges" className="btn btn-sm btn-success">Apply Changes</button>
                  </div>
                </span>)
                : ""}



              {props.pageName == "Mark to Market" ? <div>
                <Button className="float-right" size='sm' color="secondary" onClick={
                  getLastPrice
                }>

                  {loader ? (<ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ "justifyContent": "center" }}
                    wrapperClassName=""
                    visible={true}
                  />) : 'Get last price'
                  }
                </Button>
                <Button className="float-right" size='sm' color="secondary" id="linkToggler">
                  View exchange rates
                </Button></div> : ""}

            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>

          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>

              {props.pageName == "Web Links" ? (
                <>
                  <InputGroup className="search-bar">
                    <Button color="link" onClick={e => {
                      downloadWebLinks()
                    }} id="downloadWebLinksButton">
                      <i className="tim-icons icon-cloud-download-93" />
                    </Button>{` `}
                    <UncontrolledTooltip placement="top" target="downloadWebLinksButton" delay={0}>
                      Download web links
                    </UncontrolledTooltip>
                  </InputGroup>
                  <InputGroup className="search-bar">
                    <Button color="link" disabled={disableUpload} id="uploadWebLinksButton" onClick={handleFileButtonClick}>
                      <i className="tim-icons icon-cloud-upload-94" />
                      <input type='file' ref={inputRef} id='uploadWebLinksInput' accept=".csv" hidden onChange={handleChange} />
                    </Button>
                    {disableUpload && <div className="UploadingTextProgress">Uploading...</div>}
                    <UncontrolledTooltip placement="top" target="uploadWebLinksButton" delay={0}>
                      Upload web links
                    </UncontrolledTooltip>
                  </InputGroup>
                </>
              ) : (
                <InputGroup className="search-bar">
                  <Button color="link" onClick={e => {
                    downloadPostions()
                  }} id="top">
                    <i className="tim-icons icon-cloud-download-93" />
                  </Button>{` `}
                  <UncontrolledTooltip placement="top" target="top" delay={0}>
                    Download all your positions as a csv file
                  </UncontrolledTooltip>
                </InputGroup>
              )}
              <InputGroup className="search-bar">

                <Button color="link" id="top2" onClick={toggleModalSearch}>

                  <i className="tim-icons icon-trash-simple" />
                </Button>
                <UncontrolledTooltip placement="top" target="top2" delay={0}>
                  Delete all your positions from our database.
                </UncontrolledTooltip>
              </InputGroup>


              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                  style={style}
                >
                  <div className="photo">
                    <img src={profilePic} referrerPolicy="no-referrer" />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <Link to="/profile">
                    <NavLink tag="li" >
                      <DropdownItem className="nav-item">Profile</DropdownItem>
                    </NavLink>
                  </Link>

                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">

                    <LogoutButton className="nav-item"></LogoutButton>

                  </NavLink>


                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        // modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
        size="sm"
      >

        <ModalBody>
          <h4 className="text-muted text-center">Are you sure you want to delete all of your data?</h4>

          <div className="row">
            <div className="col-6">



              <button className="btn btn-danger" onClick={async (e) => {

                deleteData(e)

              }}>Delete</button>
            </div>
            <div className="col-6">

              <button
                aria-label="Close"
                className="btn btn-success"
                onClick={toggleModalSearch}
              >
                Cancel
              </button>
            </div>

          </div>
        </ModalBody>


      </Modal>
      <Modal
        // modalClassName="modal-search"
        isOpen={isShowPortfolioCCYModal}
        size="sm"
      >

        <ModalBody>
          <h4 className="text-muted text-center">Please set your portfolio ccy.</h4>
          <div>
            <Input type="select" className="text-dark" name="select" id="new_portfolio_ccy">
              {availableCCY.map(items => {
                return (
                  <option value={items.currency}>{items.currency} - {items.name}</option>)
              })}

            </Input>
          </div>
          <div style={{ textAlign: "center" }} className="mt-2">
            <Button onClick={() => {
              const chnagePortfolioCCY = async () => {
                let newPortfolioCCY = document.getElementById("new_portfolio_ccy")
                if (newPortfolioCCY.value === portfolioCCY) {
                  alert.error("Please select new portfolio ccy")
                  return
                }
                const domain = "mark-to-market.eu.auth0.com";
                const accessToken = await getAccessTokenSilently({
                  audience: `https://${domain}/api/v2/`,
                  scope: "read:current_user",
                })
                let headers = {
                  'Authorization': `Bearer ${accessToken}`
                }
                axios.put(`${process.env.REACT_APP_BACKEND_API}finance/set_portfolio_ccy`, { "portfolio_ccy": newPortfolioCCY.value }, { headers: headers })
                  .then(response => {
                    setPortfolioCCY(newPortfolioCCY.value)
                    alert.success("set successfully")
                    window.location.reload()
                  })
              }
              chnagePortfolioCCY()
            }} size="sm">Save</Button>

          </div>
        </ModalBody>


      </Modal>
    </>
  );
}

export default AdminNavbar;