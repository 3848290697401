import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine-dark.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
// import 'ag-grid-community/styles/ag-theme-balhamdark.css'; // Optional theme CSS
import "../assets/css/live-position-view.css"
import { UncontrolledCollapse, Card, CardHeader, CardBody, CardTitle, CardText, Button, CardSubtitle, Table, FormGroup, Label, Input } from 'reactstrap';
import { ThreeDots } from 'react-loader-spinner';

import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import HomeContext from 'context';


import { forwardRef, memo, useImperativeHandle } from 'react';
import { useAlert } from 'react-alert';
import CustomToolTip from 'components/CustomTooltip/CustomToolTip';
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';




const NumericEditor = memo(
  forwardRef((props, ref) => {
    console.log("Props:", props.value)
    const createInitialState = () => {
      let startValue;
      let highlightAllOnFocus = true;

      if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (props.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = props.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        if (props.value.indexOf('p') != -1) {
          startValue = parseFloat(props.value.replace(/\,/g, ''));
        } else {
          startValue = parseFloat(props.value.slice(1).replace(/\,/g, ''));

        }
        console.log("Start value", startValue)
        if (props.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue || 0,
        highlightAllOnFocus,
      };
    };
    const updateData = useCallback((m2m_price) => {
      console.log(m2m_price)
      let ccy = props.data["portfolio_ccy"]
      const formatterPortfolioCCY = new Intl.NumberFormat('en-US', {
        style: 'currency', currencyDisplay: 'narrowSymbol',
        currency: ccy,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      let newDate = new Date();
      var rowNode = props.node
      var options = { hour12: false, month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
      var newM2MDate = newDate.toLocaleString("en-US", options)
      var current_valuation = ((parseFloat(m2m_price, 4) * parseFloat(props.data.Holdings.replace(/,/g, ''), 4)) / parseFloat(props.data.denominator, 4)) * parseFloat(props.data.fx_rate, 4)
      var pnl = current_valuation + parseFloat(props.data.cost_of_current_holdings)
      rowNode.setDataValue('P & L', isNaN(pnl) ? 0 : formatterPortfolioCCY.format(pnl));
      rowNode.setDataValue('Current valuation', isNaN(current_valuation) ? 0 : formatterPortfolioCCY.format(current_valuation));
      rowNode.setDataValue('M2M price last updated', newM2MDate);
    }, []);

    // updateData(value)
    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);

    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus
    );
    const refInput = useRef(null);
    const formatDate = (date) => {
      let newDate = new Date(date);
      var options = { hour12: false, month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
      return newDate.toLocaleString("en-US", options)

    }
    // focus on the input
    useEffect(() => {
      // get ref from React component
      const eInput = refInput.current;
      eInput.focus();
      if (highlightAllOnFocus) {
        eInput.select();

        setHighlightAllOnFocus(false);
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value ? eInput.value.length : 0;
        if (length > 0) {
          eInput.setSelectionRange(length, length);
        }
      }
    }, []);

    /* Utility Methods */
    const cancelBeforeStart =
      props.charPress && '1234567890'.indexOf(props.charPress) < 0;

    const isLeftOrRight = (event) => {
      return ['ArrowLeft', 'ArrowLeft'].indexOf(event.key) > -1;
    };

    const isCharNumeric = (charStr) => {
      if (charStr === '.') {
        return true
      }
      return !!/\d/.test(charStr);
    };

    const isKeyPressedNumeric = (event) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
    };

    const deleteOrBackspace = (event) => {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    };

    const finishedEditingPressed = (event) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };

    const onKeyDown = (event) => {
      if (isLeftOrRight(event) || deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }

      if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
        if (event.preventDefault) event.preventDefault();
      }

      if (finishedEditingPressed(event)) {
        props.stopEditing();
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return value;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // will reject the number if it greater than 1,000,000
          // not very practical, but demonstrates the method.
          return value > 1000000;
        },
      };
    });

    return (
      <input
        ref={refInput}
        value={value}
        onChange={(event) => {
          console.log("--->", event.target.value)
          setValue(event.target.value)
          updateData(event.target.value)
        }}
        // onKeyDown={(event) => event.target.value(event)}
        step="0.0001"
        className="form-control bg-secondary"
        type="number"
        style={{ "color": "black" }}
      />
    );
  })
);
function M2MView(props) {
  let { dispatch, total_pnl, m2m_data } = React.useContext(HomeContext)
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const { getAccessTokenSilently } = useAuth0();
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [exchangeRate, setexchangeRate] = useState([])
  const [loader, setLoader] = useState(false)
  const [accessToken, setAccessToken] = useState()
  const [portfolioCCY, setPortfolioCCY] = useState();
  const [totalPnL, setTotalPnL] = React.useState(0)
  const [colorPnL, setColorPnl] = React.useState('text-success')
  const [orderJSON, setOrderJson] = React.useState()
  const [masterColumnState, setMasterColumnState] = useState()

  const alert = useAlert()

  const formatDate = (date) => {
    let newDate = new Date(date);
    var options = { hour12: false, month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return newDate.toLocaleString("en-US", options)
  }

  const commaFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const commaFormatterFull = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 9,
  })

  const customComparator = (valueA, valueB) => {
    let val1Number
    let val2Number
    console.log(valueA, valueB)

    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }


    val1Number = parseFloat(valueA.replace(/[^\d.-]/g, ''))


    val2Number = parseFloat(valueB.replace(/[^\d.-]/g, ''))



    return val1Number - val2Number;
  }

  const caseInsensitiveComparator = (valueA, valueB) => {
    if (valueA === null && valueB === null) {
      return 0;
    }
    if (valueA === null) {
      return -1;
    }
    if (valueB === null) {
      return 1;
    }
    if (valueA.toLowerCase() > valueB.toLowerCase()) {
      return 1;
    } else {
      return -1
    }
  }
  const recList = useRef([]);

  const [columnDefs, setColumnDefs] = useState([
    { field: 'Security name', 
    filter: true, 
    comparator: caseInsensitiveComparator, 
    resizable: true, 
    hide: false, 
    type: 'leftAligned', 
    cellRenderer: "LinkComponent"
    },

    { field: 'Security ticker', filter: true, customComparator, resizable: true, hide: false, type: 'leftAligned', cellRenderer: "LinkComponent", },
    { field: 'Security ISIN', filter: true, customComparator, resizable: true, hide: false, type: 'leftAligned', cellRenderer: "LinkComponent", },
    { field: 'Security currency', filter: true, customComparator, resizable: true, hide: false, type: 'rightAligned' },
    { field: 'Holdings', filter: true, resizable: true, hide: false, comparator: customComparator, type: 'rightAligned', tooltipField: 'Holdings', tooltipComponent: CustomToolTip, tooltipComponentParams: { color: '#ececec', field: "Holdings" } },
    { field: 'M2M price last updated', filter: true, customComparator, resizable: true, hide: false, type: 'rightAligned' },
    {
      field: 'M2M price *', filter: true, resizable: true, hide: false, editable: true, onCellValueChanged: cellValueChange,
      cellEditor: NumericEditor, cellEditorPopup: true, comparator: customComparator, type: 'rightAligned',
      tooltipField: 'M2M price *', tooltipComponent: CustomToolTip, tooltipComponentParams: { color: '#ececec', field: "M2M price" },
      headerTooltip: 'double click on m2m price to update',
    },
    {
      field: 'Current valuation', filter: true, resizable: true, hide: false, comparator: customComparator, type: 'rightAligned',
      tooltipField: 'Current valuation', tooltipComponent: CustomToolTip, tooltipComponentParams: { color: '#ececec', field: "Current valuation" }
    },
    {
      field: 'P & L', filter: true, resizable: true, hide: false, comparator: customComparator, type: 'rightAligned',
      tooltipField: 'P & L', tooltipComponent: CustomToolTip, tooltipComponentParams: { color: '#ececec', field: "P & L" }
    },

    // These fields are for calculation purpose
    { field: 'denominator', filter: true, customComparator, resizable: true, hide: true },
    { field: 'fx_rate', filter: true, customComparator, resizable: true, hide: true },
    { field: 'cost_of_current_holdings', filter: true, customComparator, resizable: true, hide: true },
    { field: 'id', filter: true, customComparator, resizable: true, hide: true },
    { field: 'portfolio_ccy', filter: true, customComparator, resizable: true, hide: true },
    { field: 'holdings_full', filter: true, customComparator, resizable: true, hide: true },
    { field: 'm2m_price_full', filter: true, customComparator, resizable: true, hide: true },
    { field: 'current_valuation_full', filter: true, customComparator, resizable: true, hide: true },
    { field: 'pnl_full', filter: true, customComparator, resizable: true, hide: true },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    cellDataType: false
  }));

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  function onGridReady(gridRef) {
    console.log("HEAD")
    autoSizeAll(false)
  }
  function LinkComponent(props) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: props.value,
        }}
      ></p>
    );
  }

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    // console.log('ClickEvent', event);
    if (event.colDef.field == "Security name") {
      const link = recList.current[event.rowIndex]?.security_urls?.[0]?.security_name_url;
      if (link) {
        window.open(link, "_blank");
      }
    }
    if (event.colDef.field == "Security ticker") {
      const link = recList.current[event.rowIndex]?.security_urls?.[0]?.security_ticker_url;
      if (link) {
        window.open(link, "_blank");
      }
    }
    if (event.colDef.field == "Security ISIN") {
      const link = recList.current[event.rowIndex]?.security_urls[0]?.security_isin_url;
      if (link) {
        window.open(link, "_blank");
      }
    }
  }, []);

  // Example of consuming Grid Event
  const cellValueChange = useCallback((event) => {
    let cancelled = false
    async function mainFun() {

      if (event.colDef.field == 'M2M price *') {
        console.log('cellChange', event);
        let formatterSecurityCCY = new Intl.NumberFormat('en-US', {
          style: 'currency', currencyDisplay: 'narrowSymbol',
          currency: event.data['Security currency'] === 'GBX' ? 'GBP' : event.data['Security currency'],
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        let formatterSecurityCCYFull = new Intl.NumberFormat('en-US', {
          style: 'currency', currencyDisplay: 'narrowSymbol',
          currency: event.data['Security currency'] === 'GBX' ? 'GBP' : event.data['Security currency'],
          minimumFractionDigits: 9,
        });
        let value = event.value;
        if (!isNaN(value)) {
          event.node.setDataValue('M2M price *', event.data["Security currency"] == "GBX" ? `${commaFormatter.format(parseFloat(value))} p` : formatterSecurityCCY.format(parseFloat(value)))
        }
        let id = event.data.id;
        const domain = "mark-to-market.eu.auth0.com";
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
        let url = `${process.env.REACT_APP_BACKEND_API}finance/set_m2m_price`
        let payload = {
          id: id,
          m2m_price: parseFloat(value.split(",").join(""))
        }
        let headers = {
          "Authorization": `Bearer ${accessToken}`
        }


        axios.put(url, payload, { headers: headers }).then(response => {
          console.log(response)
          cancelled = true
        })

        return
      }
    }

    mainFun()

  }, ['dependency'])

  // Example load data from sever
  useEffect(() => {
    const getLivePositions = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
        'Authorization': `Bearer ${accessToken}`
      }
      let portfolio_ccy = ''
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { headers: headers }).then(res => {
        console.log(">>", res.data)
        if (res.data.visibility_json) {

          setTimeout(() => {

            const savedState = JSON.parse(JSON.parse(res.data.visibility_json));
            if (!savedState.livePosition && !savedState.flatPosition && !savedState.m2m) {

              setMasterColumnState({ old_state: savedState })
            } else {

              console.log("--->>>", savedState)
              setMasterColumnState(savedState)

              gridRef.current.columnApi.applyColumnState({ state: savedState.m2m, applyOrder: true });
            }

          }, 2000);

        }
        if (res.data.order_json) {
          setOrderJson(JSON.parse(res.data.order_json))
        }
        // console.log(JSON.parse(JSON.parse(res.data.order_json)))

      })
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, { headers: headers }).then(response => {

        portfolio_ccy = response.data.portfolio_ccy
        setPortfolioCCY(portfolio_ccy)
        dispatch({ type: "UPDATE_PORTFOLIO_CCY", payload: { portfolio_ccy: portfolio_ccy } })
        const formatterPortfolioCCY = new Intl.NumberFormat('en-US', {
          style: 'currency', currencyDisplay: 'narrowSymbol',
          currency: portfolio_ccy,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,

        });
        const formatterPortfolioCCYFull = new Intl.NumberFormat('en-US', {
          style: 'currency', currencyDisplay: 'narrowSymbol',
          currency: portfolio_ccy,
          minimumFractionDigits: 9,

        });

        let formatterSecurityCCY
        let formatterSecurityCCYFull
        axios.get(`${process.env.REACT_APP_BACKEND_API}finance/live_positions?is_m2m=1`, { headers: headers })
          .then(rowData => {
            let finalRowData = []
            let tempTotalPnL = 0
            if (rowData.data.live_positions.length > 0) {

              let fx_rate = ""
              rowData.data.live_positions?.forEach((value, index, array) => {
                recList.current = [...recList.current, value];

                formatterSecurityCCY = value?.security?.currency ? new Intl.NumberFormat('en-US', {
                  style: 'currency', currencyDisplay: 'narrowSymbol',
                  currency: value?.security?.currency === 'GBX' ? 'GBP' : value?.security?.currency,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) : 123456.789;
                formatterSecurityCCYFull = value?.security?.currency ? new Intl.NumberFormat('en-US', {
                  style: 'currency', currencyDisplay: 'narrowSymbol',
                  currency: value?.security?.currency === 'GBX' ? 'GBP' : value?.security?.currency,
                  minimumFractionDigits: 9
                }) : 123456.789;
                rowData.data.fx_rates.forEach(fxrate_obj => {

                  if (fxrate_obj.currency_pair.slice(0, 3) === value.security.currency) {
                    fx_rate = fxrate_obj.last_tick
                  } else {
                    fx_rate = 1
                  }
                })
                finalRowData.push({
                  'Security name': value.security.name,
                  'Security ticker': value.security.ticker,
                  'Security ISIN': value.security.isin,
                  'Security currency': value.security.currency,
                  'Holdings': commaFormatter.format(parseFloat(value.net_sum)),
                  'M2M price last updated': formatDate(value.last_updated_m2m_price),
                  'M2M price *': value.security.currency ? (value.security.currency == "GBX" ? commaFormatter.format(value.m2m_price) + " p" : formatterSecurityCCY.format(value.m2m_price)) : 123456.789,
                  'Current valuation': formatterPortfolioCCY.format(value.current_valuation),
                  'P & L': formatterPortfolioCCY.format(value.pnl),
                  'fx_rate': fx_rate,
                  'denominator': value.security.denominator,
                  'cost_of_current_holdings': value.cost_of_current_holdings,
                  'id': value.id,
                  'portfolio_ccy': portfolio_ccy,
                  'holdings_full': commaFormatterFull.format(parseFloat(value.net_sum)),
                  'm2m_price_full': value.security.currency ? (value.security.currency == "GBX" ? commaFormatterFull.format(value.m2m_price) + " p" : formatterSecurityCCYFull.format(value.m2m_price)) : 123456.789,
                  'current_valuation_full': formatterPortfolioCCYFull.format(value.current_valuation),
                  'pnl_full': formatterPortfolioCCYFull.format(value.pnl),



                })

                tempTotalPnL += value.pnl

              })
            }
            console.log("FINAL ROW DATA", finalRowData)
            setRowData(finalRowData)
            dispatch({ type: "UPDATE_M2M_DATA", payload: { m2m_data: finalRowData } })
            setAccessToken(accessToken)
            setexchangeRate(rowData.data.fx_rates)
            let text_color = ""
            if (tempTotalPnL >= 0) {
              setColorPnl("text-success")
              text_color = "text-success"
            } else {
              text_color = "text-danger"
            }
            dispatch({ type: "UPDATE_TOTAL_PNL", payload: { total_pnl: formatterPortfolioCCY.format(tempTotalPnL), text_color: text_color } })
            setTimeout(() => {
              autoSizeAll(false)

            }, 2000);

          })

      })
    }
    getLivePositions()
  }, []);

  // Example using Grid's API
  const buttonListener = useCallback(e => {
    gridRef.current.api.deselectAll();
  }, []);
  const onColumnMoved = (e) => {
    const savedState = gridRef.current.columnApi.getColumnState();
    let tempMasterColumnState = masterColumnState
    console.log(masterColumnState)
    tempMasterColumnState["m2m"] = savedState
    setMasterColumnState(tempMasterColumnState)
    let headers = {
      'Authorization': `Bearer ${accessToken}`

    }
    axios.post(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { order_json: orderJSON, visibility_json: JSON.stringify(tempMasterColumnState) }, { headers: headers }).then(res => {

    })

  }

  const postSortRows = params => {
    let rowNodes = params.nodes;

    let recListData = recList?.current

    let filtered_data = []
    for (let index = 0; index < rowNodes?.length; index++) {
      let filtered = recListData?.find((item) => item?.id === rowNodes?.[index]?.data?.id)
      filtered_data.push(filtered)
    }

    recList.current = filtered_data
  };

  return (<div className="content" >
    <Card >
      <CardBody >

        <CardSubtitle className="text-primary" >

          <>
            <UncontrolledCollapse toggler="#linkToggler">
              <Card>
                <CardBody>
                  <Table className="mt-0" >

                    <tbody>

                      {exchangeRate.length > 0 ? exchangeRate.map((item, index) => {
                        return (
                          <tr>
                            <td className="text-left" key={index} > {item.currency_pair} </td>
                            <td className="text-left" key={index + 1} > {item.last_tick} </td>
                          </tr>
                        )
                      }) : "Not found"}

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </>

        </CardSubtitle>

        { /* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}

        <div className="ag-theme-material m2m-positions-records" style={{ height: "85vh" }} >

          <AgGridReact ref={gridRef} // Ref for accessing Grid's API
            rowData={m2m_data} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowHeight={25}
            headerHeight={35}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection='multiple' // Options - allows click selection of rows
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            onCellClicked={cellClickedListener}
            postSortRows={postSortRows}
            onGridReady={onGridReady}
            onCellValueChanged={e => { cellValueChange(e) }}
            onDragStopped={onColumnMoved}
          // Optional - registering for Grid Event 
          // Optional - registering for Grid Event
          />

        </div>
      </CardBody>
    </Card>
  </div>

  );
}

export default M2MView