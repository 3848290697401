/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import HomeLayout from "layouts/Home/Home.js";
import SummaryLayout from "layouts/Summary/Summary.js";
import LivePositionLayout from "layouts/LivePositions/LivePosition.js";
import FlatPositionLayout from "layouts/FlatPosition/FlatPosition.js";
import UploadPosition from "layouts/UploadPosition/UploadPosition.js";
import WebLinks from "layouts/WebLinks/WebLinks.js";
import PrivateRoute from "components/ProtectedRoute/ProtectedRoute";
import M2M from "layouts/M2M/m2m.js";
import './assets/css/weblinks.css'

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { Auth0Provider } from "@auth0/auth0-react";
import { render } from 'react-dom'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Profile from "layouts/ProfileLayout/profile-layout";
import MyData from "layouts/myData/myData";
import Splits from "layouts/Splits/Splits";
import Dividends from "layouts/Dividends/Dividends";
import { store } from './app/store'
import { Provider } from 'react-redux'
import AddPositon from "views/AddPosition";



const root = ReactDOM.createRoot(document.getElementById("root"));
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 10000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}



root.render(
  <Provider store={store}>

    <AlertProvider template={AlertTemplate} {...options}>
      <Auth0Provider
        domain="mark-to-market.eu.auth0.com"
        clientId="EsuufywLHDR4RqUSfS7Zvua4mI7LyzXo"
        redirectUri={window.location.origin}
      >
        <ThemeContextWrapper>
          <BackgroundColorWrapper>
            <BrowserRouter>
              <Switch>
                <PrivateRoute exact path="/add-position" component={(props) => <UploadPosition {...props} />} />
                <PrivateRoute path="/summary" component={(props) => <SummaryLayout {...props} />} />
                <PrivateRoute exact path="/live-positions" component={(props) => <LivePositionLayout {...props} />} />
                <PrivateRoute exact path="/web-links" component={(props) => <WebLinks {...props} />} />
                <PrivateRoute exact path="/flat-positions" component={(props) => <FlatPositionLayout {...props} />} />
                <PrivateRoute exact path="/admin" component={(props) => <AdminLayout {...props} />} />
                <PrivateRoute exact path="/rtl" component={(props) => <RTLLayout {...props} />} />
                <PrivateRoute exact path="/m2m" component={(props) => <M2M {...props} />} />
                <PrivateRoute exact path="/" component={(props) => <HomeLayout {...props} />} />
                <PrivateRoute exact path="/profile" component={(props) => <Profile {...props} />} />
                <PrivateRoute exact path="/my-data/:transactionType?" component={(props) => <MyData {...props} />} />
                <PrivateRoute exact path="/dividends" component={(props) => <Dividends {...props} />} />
                <PrivateRoute exact path="/splits" component={(props) => <Splits {...props} />} />

              </Switch>
            </BrowserRouter>
          </BackgroundColorWrapper>
        </ThemeContextWrapper>
      </Auth0Provider>
    </AlertProvider>
  </Provider>

);
