import AddPositionForm from 'components/AddPositionForm/AddPositionForm';
import { AutoSuggest } from 'components/Autosuggest/Autosuggest';
import React, { useReducer } from 'react'
import {
  FormGroup,
  Label,
} from "reactstrap";
import homeReducer from '../reducers';



import { Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import "../assets/css/home-view.css";
import HomeContext from 'context';
import { useAuth0 } from '@auth0/auth0-react';


function Home(props) {
  let initialState = {
    security_ticker: "",
    security_name: "",
    ISIN: "",
    security_currency:""
  }
  const [mainState, dispatch] = useReducer(homeReducer, initialState)
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setaccessToken] = React.useState(null);

  React.useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "mark-to-market.eu.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        setaccessToken(accessToken)
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);
  return (
    <HomeContext.Provider value={{ security_currency:mainState.security_currency,security_ticker: mainState.security_ticker, security_name: mainState.security_name, ISIN: mainState.ISIN, dispatch: dispatch }}>
      <div className="content">
        <div>


          <form >
            <FormGroup>
              <Label className="h3 text-white mb-3" for="exampleEmail">Search Ticker</Label>
              <AutoSuggest placeholder="Enter security name, ticker or isin to search" />
            </FormGroup>

          </form>


        </div>
        <Card className="mt-2">
          <CardBody>
            <AddPositionForm user={props.user} accessToken={accessToken}></AddPositionForm>
          </CardBody>
        </Card>
      </div>
    </HomeContext.Provider>
  )

}

export default Home
