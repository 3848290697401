import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import HomeContext from 'context';
import homeReducer from '../../reducers';
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import LivePositionView from "views/LivePosition";


import { useAuth0 } from "@auth0/auth0-react";
import NotAuthorized from "components/NotAuthorized/NotAuthorized";
import MyDataView from "views/myDataView";



var ps;

function MyData(props) {
  const location = useLocation();
  const { user,isAuthenticated,isLoading} = useAuth0();
  let initialState={
    security_ticker:"",
    security_name :"",
    ISIN:"",
    m2m_time:"",
    m2m_price:"",
    m2m_current_valuation:"",
    m2m_p_and_l:"",
    total_pnl:"",
    trade_date_filter:"",
    transaction_type_filter:"",
    is_mydata_fetched:false,
    positions:null,

  }
  const [mainState, dispatch] = React.useReducer(homeReducer, initialState)

  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Mark To Market";
  };

  if (isLoading) {
    return <div className="height-100 page-hero flex-column d-flex d-flex-col align-items-center justify-content-center">Loading ...</div>;
  }

  if (!isAuthenticated){
    return (
      <NotAuthorized/>
    )
  }

  
  return (
<HomeContext.Provider value={{security_ticker:mainState.security_ticker,security_name:mainState.security_name,positions:mainState.positions,text_color:"text-green",total_pnl:mainState.total_pnl,m2m_time:mainState.m2m_time,m2m_price:mainState.m2m_price,m2m_current_valuation:mainState.m2m_current_valuation,m2m_p_and_l:mainState.m2m_p_and_l,is_mydata_fetched:mainState.is_mydata_fetched,transaction_type_filter:mainState.transaction_type_filter,trade_date_filter:mainState.trade_date_filter,dispatch: dispatch}}>

    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            <Sidebar
              routes={routes}
              logo={{
                outterLink: "/",
                text: "Mark To Market",
                imgSrc: logo
              }}
              toggleSidebar={toggleSidebar}
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
                userImage={user.picture}
                pageName="My Data"
              />
              <Switch>
                {getRoutes(routes)}
              </Switch>
              <MyDataView user={user} />
              {
                  // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }

            </div>
          </div>
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  </HomeContext.Provider>

  );
}

export default MyData;