import React from 'react'
import { Card, CardTitle, CardHeader, CardBody, Row, Col, Table } from 'reactstrap';
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash"
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Link } from 'react-router-dom';

function Summary(props) {
  const [labels, setlabels] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [data, setdata] = React.useState()

  const [loaded, setloaded] = React.useState(true)
  const [portfolioCCY, setportfolioCCY] = React.useState("")
  const { getAccessTokenSilently } = useAuth0();
  const gridRef = React.useRef();
  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    filter: true
  }));

  let portfolio_ccy = ''
  React.useEffect(() => {
    const getSummary = async () => {

      let url = `${process.env.REACT_APP_BACKEND_API}finance/summary?user_id=${props.user.email}`
      let labels = []
      let data = []
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
        'Authorization': `Bearer ${accessToken}`

      }
      if (loaded) {
        axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, { headers: headers }).then(response => {
          portfolio_ccy = response.data.portfolio_ccy
          console.log(response.data)
          axios.get(url, { headers: headers }).then(response => {
            let tempData = []
            let formatterSecurityCCY = new Intl.NumberFormat('en-US', {
              style: 'currency', currencyDisplay: 'narrowSymbol',
              currency: portfolio_ccy === 'GBX' ? 'GBP' : portfolio_ccy,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            console.log(getSymbolFromCurrency(portfolio_ccy))
            response.data.forEach(item => {
              // console.log(item)
              labels.push(item.transaction_type)
              data.push(parseFloat(item.total_amount))
              tempData.push({ transaction_type: item.transaction_type, total_amount: formatterSecurityCCY.format(item.total_amount) })

            })
            setlabels(labels)
            setdata(tempData)
            console.log(response.data)
            console.log(labels, data)
            setTotal(data.reduce(function (a, b) { return a + b; }, 0))
            console.log(parseFloat(response.data.total_amount))
            setportfolioCCY(portfolio_ccy)
            setloaded(false)
          })
        })
      }
    }
    getSummary()
  }, [])
  const customComparator = (valueA, valueB) => {
    let val1Number;
    let val2Number;
    console.log(valueA, valueB);

    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }

    val1Number = parseFloat(valueA.replace(/[^\d.-]/g, ""));

    val2Number = parseFloat(valueB.replace(/[^\d.-]/g, ""));

    return val1Number - val2Number;
  };
  const renderShowAllButton = (params) => {
    return (
      <Link to={`/my-data/${params.data.transaction_type}`}>

          Show All

      </Link>
    );
  };
  return (
    <div className="content">

      <Row>
        <Col md="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle>
                <h3 style={{ margin: 0 }}>Cash Balance: <CurrencyFormat value={total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getSymbolFromCurrency(portfolioCCY)} /></h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div
                className="ag-theme-material live-positions-records"
                style={{
                  height: "70vh",
                  width: "75vw",
                  margin: "0 auto"
                }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data} // Row Data for Rows
                  defaultColDef={defaultColDef}
                  rowHeight={25}
            headerHeight={35}

                >
                  <AgGridColumn headerName='Transaction type' field="transaction_type" flex={1} />
                  <AgGridColumn comparator={customComparator} type='rightAligned' field="total_amount" headerName='Amount' flex={1} />
                  <AgGridColumn comparator={customComparator} type='rightAligned' field="show_all" headerName='' flex={1} cellRenderer={renderShowAllButton} />
                </AgGridReact>
              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </div>
  )
}

export default Summary