import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fetchSplits: false,
  fetchDividend: false,
  dividendData: [],
  splitsData: [],
  selectedSplitSecurity: "",
  selectedDividendSecurity: "",
}

export const DividendSplitsTableSlice = createSlice({
  name: 'dividendSplitsTable',
  initialState,
  reducers: {
    fetchSplitsTrue: (state,action) => {
        state.fetchSplits= true
        state.splitsData=action.payload.splitsData
      },
      fetchSplitsFalse: (state) => {
        state.fetchSplits= false
      },
      fetchDividendTrue: (state,action) => {
        state.fetchDividend= true
        state.dividendData=action.payload.dividendData
        console.log("CLICKED " + action.payload)
      },
      fetchDividendFalse: (state) => {
        state.fetchDividend= false
      },
      setSelectedSplitSecurity: (state,action) => {
        state.selectedSplitSecurity= action.payload.security
      },
      setSelectedDividendSecurity: (state,action) => {
        state.selectedDividendSecurity= action.payload.security
      },
  },
})

// Action creators are generated for each case reducer function
export const { fetchSplitsTrue,fetchSplitsFalse,fetchDividendTrue,fetchDividendFalse,setSelectedSplitSecurity,setSelectedDividendSecurity } = DividendSplitsTableSlice.actions

export default DividendSplitsTableSlice.reducer