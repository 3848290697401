const homeReducer = (state, action) => {
    let payload = action.payload
    switch (action.type) {
        case "SEARCH_TICKET":
            return {security_currency:payload.securityCurrency, security_ticker: payload.securityTicker, security_name: payload.securityName, ISIN: payload.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "CHANGE_ISIN":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.security_name, ISIN: payload.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "CHANGE_SECURITY_TICKER":
            return {security_currency:state.security_currency, security_ticker: payload.securityTicker, security_name: state.security_name, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "CHANGE_SECURITY_NAME":
            return { security_currency:state.security_currency,security_ticker: state.security_ticker, security_name: payload.securityName, ISIN: payload.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_M2M":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: payload.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_TOTAL_PNL":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: payload.total_pnl, text_color: payload.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_M2M_DATA":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: payload.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_PORTFOLIO_CCY":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: payload.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_MYDATA_FLAG":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: payload.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_TRANSACTION_TYPE_FILTER":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: payload.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: state.positions }
        case "UPDATE_TRADE_DATE_FILTER":
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: payload.trade_date_filter, positions: state.positions }
        case "UPDATE_POSITION":
            console.log(payload)
            return {security_currency:state.security_currency, security_ticker: state.security_ticker, security_name: state.securityName, ISIN: state.ISIN, total_pnl: state.total_pnl, text_color: state.text_color, m2m_data: state.m2m_data, portfolio_ccy: state.portfolio_ccy, is_mydata_fetched: state.is_mydata_fetched, transaction_type_filter: state.transaction_type_filter, trade_date_filter: state.trade_date_filter, positions: payload.positions }

        default:
            return state;
    }
};

export default homeReducer;