/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import HomeContext from 'context';
import homeReducer from '../../reducers';

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";



import { useAuth0 } from "@auth0/auth0-react";
import NotAuthorized from "components/NotAuthorized/NotAuthorized";
import M2MView from "views/m2m";



var ps;

function M2M(props) {
  const location = useLocation();
  const { user,isAuthenticated,isLoading} = useAuth0();
  let initialState={
    security_ticker:"",
    security_name :"",
    ISIN:"",
    m2m_time:"",
    m2m_price:"",
    m2m_current_valuation:"",
    m2m_p_and_l:"",
    total_pnl:"",
    m2m_data:"",
    portfolio_ccy:""
  }
  const [mainState, dispatch] = React.useReducer(homeReducer, initialState)

  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Mark To Market";
  };

  if (isLoading) {
    return <div className="height-100 page-hero flex-column d-flex d-flex-col align-items-center justify-content-center">Loading ...</div>;
  }

  if (!isAuthenticated){
    return (
      <NotAuthorized/>
    )
  }
  return (
<HomeContext.Provider value={{m2m_data:mainState.m2m_data,text_color:"text-green",total_pnl:mainState.total_pnl,m2m_time:mainState.m2m_time,m2m_price:mainState.m2m_price,m2m_current_valuation:mainState.m2m_current_valuation,m2m_p_and_l:mainState.m2m_p_and_l,portfolio_ccy:mainState.portfolio_ccy,dispatch: dispatch}}>
    <BackgroundColorContext.Consumer>

      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            <Sidebar
              routes={routes}
              logo={{
                outterLink: "/",
                text: "Mark To Market",
                imgSrc: logo
              }}
              toggleSidebar={toggleSidebar}
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
                userImage={user.picture}
                pageName="Mark to Market"
                total_pnl={mainState.total_pnl}
                text_color={mainState.text_color}

              />
              <Switch>
                {getRoutes(routes)}
              </Switch>
              <M2MView user={user} />
              {
                  // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }

            </div>
          </div>
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />
        </React.Fragment>
      )}

    </BackgroundColorContext.Consumer>
  </HomeContext.Provider>
  );
}

export default M2M;