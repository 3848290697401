import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownItem } from "reactstrap";


const LogoutButton = (props) => {
    const { logout } = useAuth0();

    return ( <DropdownItem className={props.className} onClick = {
            () => logout({ returnTo: window.location.origin }) } >
        Log Out </DropdownItem>
    );
};

export default LogoutButton;