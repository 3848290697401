import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine-dark.css'; // Optional theme CSS
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS
import { useAuth0 } from "@auth0/auth0-react";
import CustomToolTip from "components/CustomTooltip/CustomToolTip";
import {
  Collapse,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardSubtitle,
  Table,
  FormGroup,
  Label,
  Input,
  Badge,
} from "reactstrap";
import { useAlert } from "react-alert";

import HomeContext from "context";

function WebLinksView(props) {
  // const [flatPositions, setFlatPositions] = React.useState([])
  const [loaded, setLoaded] = React.useState(true);
  const [totalPnL, setTotalPnL] = React.useState(0);
  const [colorPnL, setColorPnl] = React.useState("text-success");
  const [accessToken, setAccessToken] = useState("");

  const [nameUrl, setNameUrl] = useState("");
  const [nameTicker, setNametTicker] = useState("");
  const [nameIsin, setNameIsin] = useState("");
  const [duplicate, setDuplicate] = useState(false);
  const [urlId, setUrlId] = useState();
  const [showError, setShowError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeRate, setexchangeRate] = useState(["---", 0]);

  const [securityName, setSecurityName] = useState("");
  const [securityTicker, setSecuityTicker] = useState("");
  const [securityIsin, setSecurityIsin] = useState("");

  let { dispatch, total_pnl } = React.useContext(HomeContext);

  // Set rowData to Array of Objects, one Object per Row
  const { getAccessTokenSilently } = useAuth0();
  const caseInsensitiveComparator = (valueA, valueB) => {
    if (valueA === null && valueB === null) {
      return 0;
    }
    if (valueA === null) {
      return -1;
    }
    if (valueB === null) {
      return 1;
    }
    if (valueA.toLowerCase() > valueB.toLowerCase()) {
      return 1;
    } else {
      return -1;
    }
  };
  const alert = useAlert();

  const gridRef = React.useRef(); // Optional - for accessing Grid's API
  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    flex:1
  }));
  const customComparator = (valueA, valueB) => {
    let val1Number;
    let val2Number;
    console.log(valueA, valueB);

    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return -1;
    }
    if (!valueB) {
      return 1;
    }

    val1Number = parseFloat(valueA.replace(/[^\d.-]/g, ""));

    val2Number = parseFloat(valueB.replace(/[^\d.-]/g, ""));

    return val1Number - val2Number;
  };
  const [columnDefs, setColumnDefs] = React.useState([
    {
      field: "ticker in account",
      comparator: caseInsensitiveComparator,
      filter: true,
      resizable: true,
      hide: false,
      sort: true,
    },
    {headerName:"Securit in accout",
      field: "Security name",
      comparator: caseInsensitiveComparator,
      filter: true,
      resizable: true,
      hide: false,
      sort: true,
      cellRendererFramework: function (params) {
        console.log("Params",params)
        console.log("Params",params.data)
        return (
          <a href={params.data.Edit.security_name_url} target="_blank">
            {params.value}
          </a>
        );
      },
    },
    {
      field: "ticker name",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
      cellRendererFramework: function (params) {
        return (
          <a href={params.value} target="_blank">
            {params.value}
          </a>
        );
      },
    },
    {
      field: "ISIN",
      filter: true,
      customComparator,
      resizable: true,
      hide: false,
      cellRendererFramework: function (params) {
        return (
          <a href={params.value} target="_blank">
            {params.value}
          </a>
        );
      },
    },
    {
      field: "Edit",
      sortable: false,
      filter: true,
      hide: false,
      // colId: "view",
      cellRendererFramework: function (params) {
        return (
          <Button
            className="editbtn"
            onClick={() => handleClickButtonEdit(params)}
          >
            {" "}
            <i class="fas fa-edit"></i>{" "}
          </Button>
        );
      },
    },
  ]);
  const cellClickedListener = React.useCallback((event) => {
    console.log("cellClicked", event);
  }, []);
  const [rowData, setRowData] = React.useState();
  const [portfolioCCY, setPortfolioCCY] = React.useState();

  // Example load data from sever
  useEffect(() => {
    const getLivePositions = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(accessToken);
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      axios
        .get(`${process.env.REACT_APP_BACKEND_API}finance/web_links/`, {
          headers: headers,
        })
        .then((rowData) => {
          let finalRowData = [];
          let portfolio_ccy = "";
          axios
            .get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, {
              headers: headers,
            })
            .then((response) => {
              portfolio_ccy = response.data.portfolio_ccy;
              setPortfolioCCY(portfolio_ccy);
              const formatterPortfolioCCY = new Intl.NumberFormat("en-US", {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: portfolio_ccy,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const formatterPortfolioCCYFull = new Intl.NumberFormat("en-US", {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: portfolio_ccy,
                minimumFractionDigits: 9,
              });
              if (rowData.data.live_positions.length > 0) {
                rowData.data.live_positions.forEach((value, index, array) => {
                  finalRowData.push({
                    "ticker in account": value.security.ticker,
                    "Security name": value.security.name,
                    "ticker name": value.security_ticker_url,
                    ISIN: value.security_isin_url,
                    Edit: value
                  });
                });
              }
              setRowData(finalRowData);

              setexchangeRate(rowData?.data?.fx_rates);
            });
        });
    };
    getLivePositions();
  }, []);
  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);
  const autoSizeColumns = (params) => {
    params.api.sizeColumnsToFit();
  };

  const handleClickButtonEdit = (params) => {
    // console.log("yesssssssssssssssssssssssssss", params);
    // console.log("yes_id",params?.value?.id)
    // console.log("yes_Url_Name",params?.value?.security_name_url)
    // console.log("yes_Url_Ticker",params?.value?.security_ticker_url)
    // console.log("yes_Url_Insi",params?.value?.security_isin_url)
    setUrlId(params?.value?.id);
    setNameUrl(params?.value?.security_name_url);
    setNametTicker(params?.value?.security_ticker_url);
    setNameIsin(params?.value?.security_isin_url);

    setSecurityName(params?.value?.security.name);
    setSecuityTicker(params?.value?.security.ticker);
    setSecurityIsin(params?.value?.security.isin);

    setDuplicate(true);
    setShowError("");
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.log(`Copied "${text}" to clipboard`);
      })
      .catch((err) => {
        // console.error(`Error copying text: ${err}`);
      });
  };

  const closePopupDup = () => {
    setDuplicate(false);
    setNameUrl("");
    setNametTicker("");
    setNameIsin("");
  };

  useEffect(() => {
    const getLivePositions = async () => {
      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(accessToken);
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      axios
        .get(`${process.env.REACT_APP_BACKEND_API}finance/web_links/`, {
          headers: headers,
        })
        .then((rowData) => {
          let finalRowData = [];
          let portfolio_ccy = "";
          axios
            .get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, {
              headers: headers,
            })
            .then((response) => {
              portfolio_ccy = response.data.portfolio_ccy;
              setPortfolioCCY(portfolio_ccy);
              const formatterPortfolioCCY = new Intl.NumberFormat("en-US", {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: portfolio_ccy,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const formatterPortfolioCCYFull = new Intl.NumberFormat("en-US", {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: portfolio_ccy,
                minimumFractionDigits: 9,
              });
              if (rowData.data.live_positions.length > 0) {
                rowData.data.live_positions.forEach((value, index, array) => {
                  finalRowData.push({
                    "ticker in account": value.security.ticker,
                    "Security name": value.security.name,
                    "ticker name": value.security_ticker_url,
                    ISIN: value.security_isin_url,
                    Edit: value,

                    // 'Security currency': value.security.currency,
                    // 'Holdings': commaFormatter.format(value.net_sum) === 'NaN' ? '' : commaFormatter.format(value.net_sum),
                    // 'holdings_full': commaFormatterFull.format(value.net_sum) === 'NaN' ? '' : commaFormatterFull.format(value.net_sum),
                    // 'Timestamp': value.pulled_price.timestamp ? formatDate(value.pulled_price.timestamp) : '',
                    // 'Gmtoffset': commaFormatter.format(value.pulled_price.gmtoffset) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.gmtoffset),
                    // 'gmtoffset_full': commaFormatterFull.format(value.pulled_price.gmtoffset) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.gmtoffset),
                    // 'Open': commaFormatter.format(value.pulled_price.open) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.open),
                    // 'open_full': commaFormatterFull.format(value.pulled_price.open) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.open),
                    // 'High': commaFormatter.format(value.pulled_price.high) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.high),
                    // 'high_full': commaFormatterFull.format(value.pulled_price.high) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.high),
                    // 'Low': commaFormatter.format(value.pulled_price.low) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.low),
                    // 'low_full': commaFormatterFull.format(value.pulled_price.low) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.low),
                    // 'Last tick': commaFormatter.format(value.pulled_price.last_tick) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.last_tick),
                    // 'last_tick_full': commaFormatterFull.format(value.pulled_price.last_tick) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.last_tick),
                    // 'Volume': commaFormatter.format(value.pulled_price.volume) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.volume),
                    // 'volume_full': commaFormatterFull.format(value.pulled_price.volume) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.volume),
                    // 'Previous close': commaFormatter.format(value.pulled_price.previousclose) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.previousclose),
                    // 'previous_close_full': commaFormatterFull.format(value.pulled_price.previousclose) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.previousclose),
                    // 'Change': commaFormatter.format(value.pulled_price.changed) === 'NaN' ? 0 : commaFormatter.format(value.pulled_price.changed),
                    // 'change_full': commaFormatterFull.format(value.pulled_price.changed) === 'NaN' ? 0 : commaFormatterFull.format(value.pulled_price.changed),
                    // 'Change p': commaFormatter.format(value.pulled_price.change_p) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.change_p),
                    // 'change_p_full': commaFormatterFull.format(value.pulled_price.change_p) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.change_p),
                    // 'Current valuation': formatterPortfolioCCY.format(value.current_valuation) === 'NaN' ? '' : formatterPortfolioCCY.format(value.current_valuation),
                    // 'current_valuation_full': formatterPortfolioCCYFull.format(value.current_valuation) === 'NaN' ? '' : formatterPortfolioCCYFull.format(value.current_valuation),
                    // // 'Current valuation': value.current_valuation,
                    // 'P & L': formatterPortfolioCCY.format(value.pnl) === "NaN" ? '' : formatterPortfolioCCY.format(value.pnl),
                    // 'pnl_full': formatterPortfolioCCYFull.format(value.pnl) === "NaN" ? '' : formatterPortfolioCCYFull.format(value.pnl)
                  });
                });
              }
              setRowData(finalRowData);

              setexchangeRate(rowData?.data?.fx_rates);
            });
        });
    };
    getLivePositions();
  }, []);

  const handleClickButton = (e) => {
    e.preventDefault();
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const params = {
      security_name_url: nameUrl,
      security_ticker_url: nameTicker,
      security_isin_url: nameIsin,
    };
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}finance/web_links/${urlId}/`,
        params,
        { headers: headers }
      )
      .then((res) => {
        setIsLoading(true);

        setDuplicate(false);
        // setIsLoading(true)
        // setTimeout(function () {
        //     setIsLoading(false);
        // }, 3000);
        setShowError("");
        setNameUrl("");
        setNametTicker("");
        setNameIsin("");
        // console.log('test', res.data)
        alert.success("Web Links Edit successfully");

        // Api Restart--------------------------------------------------------------------
        const getLivePositions = async () => {
          const domain = "mark-to-market.eu.auth0.com";
          const accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });
          setAccessToken(accessToken);
          let headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          // axios.get(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { headers: headers }).then(res => {
          //     // console.log(">>>>>>>", JSON.parse(JSON.parse(res.data.order_json)))
          //     if (res.data.visibility_json) {

          //         setTimeout(() => {
          //             const savedState = JSON.parse(JSON.parse(res.data.visibility_json));
          //             // console.log(savedState)
          //             // gridRef.current.columnApi.applyColumnState({ state: savedState, applyOrder: true });

          //         }, 2000);

          //     }
          //     if (res.data.order_json) {
          //         setColumnDefs(JSON.parse(JSON.parse(res.data.order_json)))
          //     }

          // })
          axios
            .get(`${process.env.REACT_APP_BACKEND_API}finance/web_links/`, {
              headers: headers,
            })
            .then((rowData) => {
              setTimeout(function () {
                setIsLoading(false);
              }, 1000);
              // window.location.reload()
              let finalRowData = [];
              let portfolio_ccy = "";
              axios
                .get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, {
                  headers: headers,
                })
                .then((response) => {
                  // console.log(response.data, "gsgsg")
                  portfolio_ccy = response.data.portfolio_ccy;
                  setPortfolioCCY(portfolio_ccy);
                  const formatterPortfolioCCY = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currencyDisplay: "narrowSymbol",
                    currency: portfolio_ccy,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  const formatterPortfolioCCYFull = new Intl.NumberFormat(
                    "en-US",
                    {
                      style: "currency",
                      currencyDisplay: "narrowSymbol",
                      currency: portfolio_ccy,
                      minimumFractionDigits: 9,
                    }
                  );
                  // console.log(rowData, "huiiiit")
                  //filterRows.current = rowData?.data?.live_positions
                  // setFilterRows(rowData?.data?.live_positions)

                  if (rowData.data.live_positions.length > 0) {
                    rowData.data.live_positions.forEach(
                      (value, index, array) => {
                        finalRowData.push({
                          "ticker in account": value.security.ticker,
                          "Security name": value.security.name,
                          "ticker name": value.security_ticker_url,
                          ISIN: value.security_isin_url,
                          Edit: value,
                          // 'Update':  handleNowClick(value.id)

                          // 'Security_name_url': value.security_name_url,
                          // 'Security_ticker_url': value.security_ticker_url,
                          // 'Security_ISIN_url': value.security_isin_url,
                          // 'Security currency': value.security.currency,
                          // 'Holdings': commaFormatter.format(value.net_sum) === 'NaN' ? '' : commaFormatter.format(value.net_sum),
                          // 'holdings_full': commaFormatterFull.format(value.net_sum) === 'NaN' ? '' : commaFormatterFull.format(value.net_sum),
                          // 'Timestamp': value.pulled_price.timestamp ? formatDate(value.pulled_price.timestamp) : '',
                          // 'Gmtoffset': commaFormatter.format(value.pulled_price.gmtoffset) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.gmtoffset),
                          // 'gmtoffset_full': commaFormatterFull.format(value.pulled_price.gmtoffset) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.gmtoffset),
                          // 'Open': commaFormatter.format(value.pulled_price.open) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.open),
                          // 'open_full': commaFormatterFull.format(value.pulled_price.open) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.open),
                          // 'High': commaFormatter.format(value.pulled_price.high) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.high),
                          // 'high_full': commaFormatterFull.format(value.pulled_price.high) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.high),
                          // 'Low': commaFormatter.format(value.pulled_price.low) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.low),
                          // 'low_full': commaFormatterFull.format(value.pulled_price.low) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.low),
                          // 'Last tick': commaFormatter.format(value.pulled_price.last_tick) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.last_tick),
                          // 'last_tick_full': commaFormatterFull.format(value.pulled_price.last_tick) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.last_tick),
                          // 'Volume': commaFormatter.format(value.pulled_price.volume) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.volume),
                          // 'volume_full': commaFormatterFull.format(value.pulled_price.volume) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.volume),
                          // 'Previous close': commaFormatter.format(value.pulled_price.previousclose) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.previousclose),
                          // 'previous_close_full': commaFormatterFull.format(value.pulled_price.previousclose) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.previousclose),
                          // 'Change': commaFormatter.format(value.pulled_price.changed) === 'NaN' ? 0 : commaFormatter.format(value.pulled_price.changed),
                          // 'change_full': commaFormatterFull.format(value.pulled_price.changed) === 'NaN' ? 0 : commaFormatterFull.format(value.pulled_price.changed),
                          // 'Change p': commaFormatter.format(value.pulled_price.change_p) === 'NaN' ? '' : commaFormatter.format(value.pulled_price.change_p),
                          // 'change_p_full': commaFormatterFull.format(value.pulled_price.change_p) === 'NaN' ? '' : commaFormatterFull.format(value.pulled_price.change_p),
                          // 'Current valuation': formatterPortfolioCCY.format(value.current_valuation) === 'NaN' ? '' : formatterPortfolioCCY.format(value.current_valuation),
                          // 'current_valuation_full': formatterPortfolioCCYFull.format(value.current_valuation) === 'NaN' ? '' : formatterPortfolioCCYFull.format(value.current_valuation),
                          // // 'Current valuation': value.current_valuation,
                          // 'P & L': formatterPortfolioCCY.format(value.pnl) === "NaN" ? '' : formatterPortfolioCCY.format(value.pnl),
                          // 'pnl_full': formatterPortfolioCCYFull.format(value.pnl) === "NaN" ? '' : formatterPortfolioCCYFull.format(value.pnl)
                        });
                      }
                    );
                  }
                  setRowData(finalRowData);

                  // setexchangeRate(rowData?.data?.fx_rates)
                });
            });
        };
        getLivePositions();
        // Api Restart end
      })
      .catch((error) => {
        setIsLoading(false);

        setShowError(error?.response?.data?.message);
        // setDuplicate(false)
        alert.error("something went wrong");
      });
  };

  const hideShowColumn = (e) => {
    console.log("e.target.value==", e.target.value);
    // console.log(e.target.checked)
    const findRequireCol = (field) => {
      return field.field === e.target.value;
    };
    if (!e.target.checked) {
      let requireField = columnDefs.find(findRequireCol);
      columnDefs.forEach((value, index) => {
        if (value === requireField) {
          requireField.hide = true;
          columnDefs.splice(index, 1, requireField);
        }
      });
      gridRef.current.columnApi.setColumnsVisible([e.target.value], false);

      setColumnDefs([...columnDefs]);
    } else {
      let requireField = columnDefs.find(findRequireCol);
      columnDefs.forEach((value, index) => {
        if (value === requireField) {
          requireField.hide = false;
          columnDefs.splice(index, 1, requireField);
        }
      });
      gridRef.current.columnApi.setColumnsVisible([e.target.value], true);

      setColumnDefs([...columnDefs]);
    }
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const savedState = gridRef.current.columnApi.getColumnState();
  };

  function LinkComponent(props) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: props.value,
        }}
      ></p>
      //   <a
      //     target="_blank"
      //     rel="noopener noreferrer"
      //     href={"#" + props.value}
      //   >
      //     {props.value}
      //   </a>
    );
  }

  const onColumnMoved = (e) => {
    const savedState = gridRef.current.columnApi.getColumnState();
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    // axios.post(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { order_json: JSON.stringify(columnDefs), visibility_json: JSON.stringify(savedState) }, { headers: headers }).then(res => {
    // axios.post(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { order_json: JSON.stringify(columnDefs), visibility_json: JSON.stringify(savedState) }, { headers: headers }).then(res => {

    // })
  };

  if (isLoading) {
    return (
      <div className="height-100 page-hero flex-column d-flex d-flex-col align-items-center justify-content-center">
        Loading ...
      </div>
    );
  }

  return (
    <>
      <div className="weblinkpagetop">
        <div
          className="weblinkpage"
          style={{
            display: duplicate ? "grid" : "none",
            width: "100%",
            height: "90vh",
            position: "fixed",
            top: "10px",
            left: "10px",
            borderRadius: "20px",
            zIndex: 100000,
          }}
        >
          <div className="weblinkbackground">
            <div
              style={{ cursor: "pointer", top: "30px", right: "10px" }}
              onClick={(e) => closePopupDup()}
              className="mt-3 float-right mr-4 test"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="white"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
            <h1 className="mt-5 ml-4">Edit Web Links </h1>
            <div className="form">
              <div>
                <FormGroup key="12" className="col-md-12">
                  <div
                    style={{ marginBottom: "15px" }}
                    className="Security_weblink_1"
                  >
                    <div className="Security_sec_1">
                      <h4 className="Securityname">Security Name</h4>
                      <h4>
                        : {securityName ? securityName : "N/A"}
                        <span
                          style={{ marginLeft: "10px" }}
                          title="copy"
                          onClick={(e) => handleCopyToClipboard(securityName)}
                        >
                          <i className="fas fa-light fa-copy" />
                        </span>
                      </h4>
                    </div>
                    <div className="Security_sec_1">
                      <h4 className="Securityname">Security Ticker</h4>
                      <h4>
                        : {securityTicker ? securityTicker : "N/A"}
                        <span
                          title="copy"
                          style={{ marginLeft: "10px" }}
                          onClick={(e) => handleCopyToClipboard(securityTicker)}
                        >
                          <i className="fas fa-light fa-copy" />
                        </span>
                      </h4>
                    </div>
                    <div className="Security_sec_1">
                      <h4 className="Securityname">Security ISIN</h4>
                      <h4>
                        : {securityIsin ? securityIsin : "N/A"}
                        <span
                          title="copy"
                          style={{ marginLeft: "10px" }}
                          onClick={(e) => handleCopyToClipboard(securityIsin)}
                        >
                          <i className="fas fa-light fa-copy" />
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className="Security_weblink">
                    <div className="Security_sec">
                      <Label
                        htmlFor="broker_reference_id"
                        className="text-white"
                      >
                        Security Name Link
                      </Label>
                      <Input
                        type="url"
                        id="broker_reference_id"
                        value={nameUrl}
                        onChange={(e) => setNameUrl(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div>
                <FormGroup key="14" className="col-md-12">
                  <div className="Security_weblink">
                    <div className="Security_sec">
                      <Label
                        htmlFor="total_consideration_local_ccy"
                        className="text-white"
                      >
                        Security Ticker Link
                      </Label>
                      <Input
                        type="url"
                        id="total_consideration_local_ccy"
                        placeholder=""
                        required
                        autoComplete="off"
                        value={nameTicker}
                        onChange={(e) => setNametTicker(e.target.value)}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div>
                <FormGroup key="13" className="col-md-12">
                  <div className="Security_weblink">
                    <div className="Security_sec">
                      <Label
                        htmlFor="transaction_description_id"
                        className="text-white"
                      >
                        Security Isin Link
                      </Label>
                      <Input
                        type="url"
                        id="transaction_description_id"
                        placeholder=""
                        autoComplete="off"
                        value={nameIsin}
                        onChange={(e) => setNameIsin(e.target.value)}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <Button
                className="addlinkbtn"
                type="submit"
                color="primary"
                id="add_position_btn"
                onClick={(e) => handleClickButton(e)}
              >
                Add Links
              </Button>
            </div>
            <div>
              <ul className="errortext">
                {showError?.security_name_url && (
                  <li>Security Name Link : {showError?.security_name_url}</li>
                )}
                {showError?.security_ticker_url && (
                  <li>
                    Security Ticker Link : {showError?.security_ticker_url}
                  </li>
                )}
                {showError?.security_name_url && (
                  <li>Securit Isin Link : {showError?.security_isin_url}</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <Card>
          <CardBody>
            <CardSubtitle className="mb-2 text-primary"></CardSubtitle>
            {/* <Collapse toggler="#linkToggler,#buttonToggler" >
                    <Card >
                        <CardBody >
                            <Table className="" >

                                <tbody >
                                    {
                                        exchangeRate !== null && exchangeRate?.length > 0 ? exchangeRate?.map((item, index) => {


                                            return (
                                            <tr key={index+Math.random(index)*100}>
                                                <td className="text-left" key={index+Math.random(index)*100} >
                                                    {item.currency_pair}
                                                </td>
                                                
                                                <td className="text-left" key={index + 1}>
                                                    {item.last_tick}
                                                </td>
                                            </tr>
                                            )
                                        }) : "Not found"
                                    }

                                </tbody>
                            </Table > </CardBody>
                    </Card >
                </Collapse> */}
            <Collapse toggler="#exchangeRateToggler1">
              <Card>
                <CardBody>
                  <div className="row">
                    {columnDefs?.map?.((value, index, array) => {
                      if (value.field.indexOf("_") === -1) {
                        return (
                          <div
                            className="col-2"
                            key={index + Math.random(index) * 100}
                          >
                            <FormGroup
                              key={index + Math.random(index) * 100}
                              check
                            >
                              <Label
                                key={index + Math.random(index) * 100}
                                check
                              >
                                <Input
                                  type="checkbox"
                                  value={value.field}
                                  checked={!value.hide}
                                  onChange={(e) => {
                                    hideShowColumn(e);
                                  }}
                                  key={index + Math.random(index) * 100}
                                />{" "}
                                <span
                                  key={index + Math.random(index) * 100}
                                  className="form-check-sign"
                                >
                                  <span
                                    className="check"
                                    key={index + Math.random(index) * 100}
                                  >
                                    {" "}
                                    {value.field}{" "}
                                  </span>{" "}
                                </span>{" "}
                              </Label>{" "}
                            </FormGroup>
                          </div>
                        );
                      }
                    })}
                  </div>{" "}
                </CardBody>{" "}
              </Card>{" "}
            </Collapse>
            {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
            <div className="ag-theme-material mt-3" style={{ height: "80vh" }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="multiple" // Options - allows click selection of rows
                rowGroupPanelShow={"always"}
                pivotPanelShow={"always"}
                onCellClicked={cellClickedListener}
                rowHeight={25}
            headerHeight={35}
                onDragStopped={onColumnMoved} // Optional - registering for Grid Event
                // frameworkComponents={{
                //     LinkComponent
                // }}
              />{" "}
            </div>{" "}
          </CardBody>{" "}
        </Card>
      </div>

      {/* <div className = "content" >
        <Card >
        <CardBody >


        <div className = "ag-theme-material"
        style = {
            { height: "80vh" }
        } >

        <AgGridReact ref = { gridRef } // Ref for accessing Grid's API
        // onGridReady={onGridReady}
        onFirstDataRendered = { autoSizeColumns }
        rowData = { rowData } // Row Data for Rows

        columnDefs = { columnDefs } // Column Defs for Columns
        defaultColDef = { defaultColDef } // Default Column Properties
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        animateRows = { true } // Optional - set to 'true' to have rows animate when sorted
        rowSelection = 'multiple' // Options - allows click selection of rows
        rowGroupPanelShow = { 'always' }
        pivotPanelShow = { 'always' }
        onCellClicked = { cellClickedListener } // Optional - registering for Grid Event
        /> 
        </div > 
        </CardBody> 
        </Card > 
        </div> */}
    </>
  );
}

export default WebLinksView;
