import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShow: false,
}

export const counterSlice = createSlice({
  name: 'portfolioCCYModal',
  initialState,
  reducers: {
    showModal: (state) => {
        state.isShow= true
      },
    hideModal: (state) => {
        state.isShow= false
      },
  },
})

// Action creators are generated for each case reducer function
export const { showModal,hideModal } = counterSlice.actions

export default counterSlice.reducer