import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDividendTrue, setSelectedDividendSecurity } from 'features/DividendSplitsTable/DividendSplitsTableSlice';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';


const DividendTable = (props) => {
  const [optionSecurities, SetOptionSecurities] = React.useState([])
  const dividendData = useSelector((state) => state.dividendSplitsTable.dividendData);
  const selectedSecurity = useSelector((state) => state.dividendSplitsTable.selectedDividendSecurity);
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    SetOptionSecurities([...props.optionSecurities])
  }, [...props.optionSecurities])

  const columnDefs = [
    { headerName: 'Dividend Date', field: 'dividendDate' },
    { headerName: 'Stock', field: 'stock' },
    { headerName: 'Dividend Amount', field: 'dividendAmount' },
    { headerName: 'Transaction Date', field: 'yourPosition' },
    { headerName: 'Transaction', field: 'transaction' },
    { headerName: 'No. of shares', field: 'no_of_shares' },
    { headerName: 'NET Position', field: 'netPosition' },
    { headerName: 'Received Dividend Amount', field: 'dividendAmount2' },
  ];

  function roundDividendAmount2(data) {
    const roundedData = [];
  
    for (const item of data) {
      // Round dividendAmount2 to 2 decimal places
      const roundedAmount2 = parseFloat(item.dividendAmount2).toFixed(2);
  
      // Create a new dictionary with rounded dividendAmount2
      const newItem = {
        ...item,
        dividendAmount2: roundedAmount2
      };
  
      // Add the new dictionary to the new array
      roundedData.push(newItem);
    }
  
    return roundedData;
  }
  


  return (
    <>
      <div className='mt-2' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <h4 className='m-2'>Dividends</h4>
        <select className="btn-sm mr-3" style={{ width: "300px" }} aria-label="Default select example" onChange={async e => {

          dispatch(setSelectedDividendSecurity({ security: e.target.value }))
          const domain = "mark-to-market.eu.auth0.com";

          const accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });

          let url = `${process.env.REACT_APP_BACKEND_API}finance/get-dividends/`

          let headers = {
            "Authorization": `Bearer ${accessToken}`
          }
          axios.get(url, { params: { security: e.target.value }, headers: headers }).then(response => {
            console.log("--------------------------------->", response)
            // SetOptionSecurities(response.data.data)
            dispatch(fetchDividendTrue({ dividendData: roundDividendAmount2(response.data.data) }))
          })
        }}>

          {optionSecurities.map(item => (
            item == selectedSecurity ? <option selected value={item} >{item}</option> : <option value={item} >{item}</option>
          ))}
        </select>
      </div>
      <div className="ag-theme-material mt-3" style={{ height: '75vh' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={dividendData}
          rowHeight={25}
          headerHeight={35}
        />
      </div>
    </>
  );
};

export default DividendTable;
