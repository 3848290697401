import React, { useCallback, useRef } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'

import axios from 'axios'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine-dark.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import { useAuth0 } from "@auth0/auth0-react";
import CustomToolTip from 'components/CustomTooltip/CustomToolTip';


import HomeContext from 'context';

function FlatPosition(props) {
    // const [flatPositions, setFlatPositions] = React.useState([])
    const [loaded, setLoaded] = React.useState(true)
    const [totalPnL, setTotalPnL] = React.useState(0)
    const [colorPnL, setColorPnl] = React.useState('text-success')
    const [orderJSON, setOrderJson] = React.useState()

    const recList = useRef([]);

    const [masterColumnState, setMasterColumnState] = React.useState()
    let { dispatch, total_pnl } = React.useContext(HomeContext)

    // Set rowData to Array of Objects, one Object per Row
    const { getAccessTokenSilently } = useAuth0();
    const caseInsensitiveComparator = (valueA, valueB) => {
        if (valueA === null && valueB === null) {
            return 0;
        }
        if (valueA === null) {
            return -1;
        }
        if (valueB === null) {
            return 1;
        }
        if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return 1;
        } else {
            return -1
        }
    }
    const gridRef = React.useRef(); // Optional - for accessing Grid's API
    const defaultColDef = React.useMemo(() => ({
        sortable: true,
        flex: 1
    }));
    const customComparator = (valueA, valueB) => {
        let val1Number
        let val2Number
        console.log(valueA, valueB)

        if (!valueA && !valueB) {
            return 0;
        }
        if (!valueA) {
            return -1;
        }
        if (!valueB) {
            return 1;
        }


        val1Number = parseFloat(valueA.replace(/[^\d.-]/g, ''))


        val2Number = parseFloat(valueB.replace(/[^\d.-]/g, ''))



        return val1Number - val2Number;
    }
    const [columnDefs, setColumnDefs] = React.useState([
        {
            field: 'security_name_url',
            comparator: caseInsensitiveComparator,
            filter: true, customComparator,
            hide: true,
            resizable: true
        },

        {
            field: 'security_ticker_url',
            comparator: caseInsensitiveComparator,
            filter: true, customComparator,
            hide: true,
            resizable: true,
        },

        {
            field: 'Security name',
            comparator: caseInsensitiveComparator,
            filter: true, customComparator,
            hide: false,
            resizable: true,
            cellRenderer: (params) => {
                return params.data["security_name_url"] ? (
                    <span className='text-info'>{params.data["Security name"]}</span>
                ) : (
                    <span >{params.data["Security name"]}</span>
                )
            },
            cellClass: "my-class"
        },


        {
            field: 'Security ticker',
            filter: true,
            customComparator,
            hide: false,
            resizable: true,
            cellClass: "my-class",
            cellRenderer: (params) => {
                return params.data["security_ticker_url"] ? (
                    <span className='text-info'>{params.data["Security ticker"]}</span>
                ) : (
                    <span >{params.data["Security ticker"]}</span>
                )
            },
        },

        {
            field: 'Security ISIN',
            filter: true,
            customComparator,
            hide: false,
            resizable: true,

            cellClass: "my-class"
        },

        { field: 'Security currency', filter: true, customComparator, hide: false, resizable: true },
        { field: 'Realized P & L', filter: true, hide: false, comparator: customComparator, type: 'rightAligned', tooltipField: 'Realized P & L', tooltipComponent: CustomToolTip, tooltipComponentParams: { color: '#ececec', field: "Realized P & L" }, resizable: true },
        { field: 'realized_pnl', filter: true, hide: true, comparator: customComparator, type: 'rightAligned', resizable: true },
    ]);
    const cellClickedListener = useCallback((event) => {
        // console.log('ClickEvent', event);
        if (event.colDef.field == "Security name") {
            const link = recList.current[event.rowIndex]?.security_urls?.[0]?.security_name_url;
            if (link) {
                window.open(link, "_blank");
            }
        }
        if (event.colDef.field == "Security ticker") {
            const link = recList.current[event.rowIndex]?.security_urls?.[0]?.security_ticker_url;
            if (link) {
                window.open(link, "_blank");
            }
        }
        if (event.colDef.field == "Security ISIN") {
            const link = recList.current[event.rowIndex]?.security_urls[0]?.security_isin_url;
            if (link) {
                window.open(link, "_blank");
            }
        }
    }, []);
    // const cellClickedListener = React.useCallback(event => {
    //     console.log('cellClicked', event);
    // }, []);
    const [rowData, setRowData] = React.useState();
    const [portfolioCCY, setPortfolioCCY] = React.useState();

    const [accessToken, setAccessToken] = React.useState()
    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);
    function LinkComponent(props) {
        console.log("+++++====+++++", props)

        return (
            <p
                dangerouslySetInnerHTML={{
                    __html: props.value,
                }}
            ></p>
        );
    }

    React.useEffect(() => {

        const getFlatPositions = async () => {

            const domain = "mark-to-market.eu.auth0.com";

            const accessToken = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
            });

            let url = `${process.env.REACT_APP_BACKEND_API}finance/flat_positions`
            setAccessToken(accessToken)

            let headers = { "Authorization": `Bearer ${accessToken}` }
            let portfolio_ccy = ""
            let formatter
            let formatterFull
            await axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, { headers: headers }).then(response => {

                portfolio_ccy = response.data.portfolio_ccy
                formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: portfolio_ccy,
                    currencyDisplay: 'narrowSymbol',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,

                });

                formatterFull = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: portfolio_ccy,
                    currencyDisplay: 'narrowSymbol',
                    minimumFractionDigits: 9,

                });
                setPortfolioCCY(portfolio_ccy)

            })
            await axios.get(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { headers: headers }).then(res => {
                console.log(">>", res.data)
                if (res.data.visibility_json) {

                    setTimeout(() => {
                        const savedState = JSON.parse(JSON.parse(res.data.visibility_json));
                        if (!savedState.livePosition && !savedState.flatPosition && !savedState.m2m) {

                            setMasterColumnState({ old_state: savedState })
                        } else {

                            console.log("--->>>", savedState)
                            setMasterColumnState(savedState)
                            gridRef.current.columnApi.applyColumnState({ state: savedState.flatPosition, applyOrder: true });
                        }

                    }, 2000);

                }
                if (res.data.order_json) {
                    setOrderJson(JSON.parse(res.data.order_json))
                }
                // console.log(JSON.parse(JSON.parse(res.data.order_json)))

            })

            axios.get(url, { headers: headers }).then(res => {
                if (loaded) {
                    let finalRowData = []
                    let totalPnL = 0
                    console.log("______________________++++____________________", res.data)
                    if (res.data.length > 0) {
                        res.data.forEach(value => {
                            recList.current = [...recList.current, value];

                            totalPnL += parseFloat(value.realized_pnl)
                            finalRowData.push({
                                'id': value.id,
                                'Security name': value.security.name,
                                'Security ticker': value.security.ticker,
                                'Security ISIN': value.security.isin,
                                'Security currency': value.security.currency,
                                'Realized P & L': formatter.format(value.realized_pnl),
                                'realized_pnl': formatterFull.format(value.realized_pnl),
                                'security_name_url': value.security_urls ? value.security_urls[0]["security_name_url"] : '',
                                'security_ticker_url': value.security_urls ? value.security_urls[0]["security_ticker_url"] : ''
                            })

                        })
                    }



                    setRowData(finalRowData)
                    let text_color = ""
                    if (totalPnL >= 0) {
                        text_color = "text-success"
                    } else {
                        text_color = setColorPnl("text-danger")

                    }

                    dispatch({ type: "UPDATE_TOTAL_PNL", payload: { total_pnl: formatter.format(totalPnL), text_color: text_color } })


                }
                setLoaded(false)

                return () => {
                    console.log("This will be logged on unmount");
                }
            })
        }
        getFlatPositions()

    }, [])


    const autoSizeColumns = params => {

        params.api.sizeColumnsToFit();

    };

    const onColumnMoved = (e) => {
        const savedState = gridRef.current.columnApi.getColumnState();
        let tempMasterColumnState = masterColumnState
        console.log(masterColumnState)
        tempMasterColumnState["flatPosition"] = savedState
        setMasterColumnState(tempMasterColumnState)
        let headers = {
            'Authorization': `Bearer ${accessToken}`

        }
        axios.post(`${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`, { order_json: orderJSON, visibility_json: JSON.stringify(tempMasterColumnState) }, { headers: headers }).then(res => {

        })

    }

    const postSortRows = params => {
        let rowNodes = params.nodes;

        let recListData = recList?.current

        let filtered_data = []
        for (let index = 0; index < rowNodes?.length; index++) {
            let filtered = recListData?.find((item) => item?.id === rowNodes?.[index]?.data?.id)
            filtered_data.push(filtered)
        }
        recList.current = filtered_data
    };

    return (<div className="content" >
        <Card >
            <CardBody >


                <div className="ag-theme-material flat-positions-records"
                    style={
                        { height: "80vh" }
                    } >

                    <AgGridReact ref={gridRef} // Ref for accessing Grid's API
                        // onGridReady={onGridReady}
                        onFirstDataRendered={autoSizeColumns}
                        rowData={rowData} // Row Data for Rows

                        columnDefs={columnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        rowSelection='multiple' // Options - allows click selection of rows
                        rowGroupPanelShow={'always'}
                        pivotPanelShow={'always'}
                        postSortRows={postSortRows}
                        onCellClicked={cellClickedListener}
                        onDragStopped={onColumnMoved} // Optional - registering for Grid Event

                        rowHeight={25}
                        headerHeight={35}
                    />
                </div >
            </CardBody>
        </Card >
    </div>
    )
}

export default FlatPosition