import React, { useContext } from 'react';
import Autosuggest from 'react-autosuggest';
import { Button,UncontrolledTooltip,Card,CardBody,CardSubtitle,CardTitle } from 'reactstrap';

import axios from 'axios';
import HomeContext from 'context';


// Imagine you have a list of languages that you'd like to autosuggest.






function renderSuggestion(suggestion) {

  return (
<Card className="my-1 hover-effect" >
    <CardBody>
        <CardSubtitle className="mb-2 text-white">{suggestion.name} | {suggestion.ticker} | {suggestion.currency}</CardSubtitle>
        <CardSubtitle className="mb-2 text-success">Country: {suggestion.country} | Exchange: {suggestion.exchange} | ISIN: {suggestion.isin} | Type: {suggestion.type}</CardSubtitle>
    </CardBody>
</Card>
  );
}


export class AutoSuggest extends React.Component {
 // assign static contextType
 static contextType = HomeContext
 
 constructor(props) {
   super();
   
   this.state = {
     value: props.value || "",
     suggestions: [],
     isLoading: false
    };
    this.props=props
    
  }

  getSuggestionValue(suggestion) {
    console.log("HEY")
    this.inputProps.context.dispatch({type:"SEARCH_TICKET",payload:{"securityTicker":suggestion.ticker,"securityName":suggestion.name,"ISIN":suggestion.isin,securityCurrency:suggestion.currency}})
    return suggestion.name;
  }
  loadSuggestions(value) {
    // Cancel the previous request
    this.setState({
      isLoading: true
    });
    var self = this;
    axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_securities`, {
      params: {
        q: value
      }
    })
    .then(function (response) {

      self.setState({
        isLoading: false,
        suggestions: response.data
      });
      return;
    })

  }

  onChange = (event, { newValue }) => {
    console.log(newValue)
    if (newValue){

      this.setState({
        value: newValue
      });
    }else{
      this.setState({
        value: ""
      });
      console.log("-________-")
        this.context.dispatch({type:"CHANGE_SECURITY_TICKER",payload:{"securityTicker":""}})
    }
  };
    
  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {

    this.setState({
      suggestions: []
    });
  };

  render() {

    const { value, suggestions, isLoading } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      className:'form-control',
      context:this.context

    };
    const status = (isLoading ? 'Loading...' : 'Type to load suggestions');
    
    return (
      <div>
        <div className="status">
          <strong>Status:</strong> {status}
        </div>
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps} />
      </div>
    );
  }
}

export default AutoSuggest