/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";

var routes = [{
        path: "/",
        name: "Add Positions",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-simple-add",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/add-position",
        name: "Upload Position",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-cloud-upload-94",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/summary",
        name: "Transactions Summary",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-paper",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/live-positions",
        name: "Live Positions",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-bar-32",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/flat-positions",
        name: "Flat Positions",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/web-links",
        name: "Web Links",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-world",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/m2m",
        name: "Mark To Market",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-sound-wave",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/my-data",
        name: "My Data",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-book-bookmark",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/splits",
        name: "Splits",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-molecule-40",
        component: Dashboard,
        layout: ""
    },
    {
        path: "/dividends",
        name: "Dividends",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-money-coins",
        component: Dashboard,
        layout: ""
    },

];
export default routes;