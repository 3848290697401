import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownItem } from "reactstrap";


const LoginButton = (props) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    if (!isAuthenticated) {

        loginWithRedirect()
    }
};

export default LoginButton;