import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, UncontrolledCollapse, Input } from 'reactstrap';
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash"
import { AgGridReact } from 'ag-grid-react';
import HomeContext from 'context';
import "assets/css/myDataView.css"
import AutoSuggest from 'components/Autosuggest/Autosuggest';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { forwardRef, memo, useImperativeHandle } from 'react';
import CustomToolTip from 'components/CustomTooltip/CustomToolTip';
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';



const cellValueChange=()=>{}
function GenericEditor(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [value, setValue] = useState(props.value);
  console.log(props)
  function handleChange(event) {
    setValue(event.target.value);

  }

  return (
    <div style={{padding:'0px 10px'}}>
      <input style={{border:"none",outline:"none"}} type="text" value={value} onChange={handleChange} />
      <button onClick={async ()=>{
        const domain = "mark-to-market.eu.auth0.com";
        const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
      await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}finance/update-mydatatable/`,
        {
          id: props.data.id,
          column:props.colDef.field,
          new_value:value
        },
        { headers: headers }
      )
      .then((res) => {
        props.node.setDataValue(props.colDef.field,value)
        props.api.stopEditing()
      });

      }} 
      className='btn btn-success  btn-sm'>save</button>
    </div>
  );
}
function MyData() {


  let { dispatch, transaction_type_filter, trade_date_filter, is_mydata_fetched, positions, security_ticker, security_name } = React.useContext(HomeContext)
  let alert = useAlert()
  const [fromTradeDate, setFromTradeDate] = React.useState()
  const [toTradeDate, setToTradeDate] = React.useState()
  const [loaded, setloaded] = React.useState(true)
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = React.useState("")
  const [isPresetTransactionType, setIsPresetTransactionType] = React.useState(false)
  const gridRef = React.useRef(); // Optional - for accessing Grid's API
  const { transactionType } = useParams();
  const [masterColumnState, setMasterColumnState] = useState();
  console.log("PARAMS => ", transactionType)

  React.useEffect(() => {
    if (typeof transactionType !== 'undefined') {
      // do something with myVariable
      console.log("Transaction Defined")
      dispatch({ type: "UPDATE_TRANSACTION_TYPE_FILTER", payload: { transaction_type_filter: transactionType } })
      dispatch({ type: "UPDATE_TRADE_DATE_FILTER", payload: { trade_date_filter: "all" } })
      dispatch({ type: "UPDATE_MYDATA_FLAG", payload: { is_mydata_fetched: true } })
      setIsPresetTransactionType(true)
      return
    }
    return
  }, [])

  React.useEffect(() => {
    if (typeof transactionType !== 'undefined') {
      applyFilter()
    }
    return
  }, [isPresetTransactionType])

  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    editable: true,
    onCellValueChanged:cellValueChange,
    cellEditor: GenericEditor, 
    cellEditorPopup: true,
    tooltipComponent: CustomToolTip, 
    headerTooltip: 'double click on m2m price to update',
  }));

  const [columnDefs, setColumnDefs] = React.useState([
    {
      field: "Id",
      filter: true,
      resizable: true,
      hide: true,
      sort: true,
    },
    {
      field: "Trade date",
      filter: true,
      resizable: true,
      hide: false,
      sort: true,

    },
    {
      field: "Settlement date",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Broker refrence",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Transaction description",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Unit cost local ccy",
      filter: true,
      resizable: true,

    },
    {
      field: "Quantity",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Brokerage local ccy",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Transaction tax local ccy",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Fx transaction cost local ccy",
      filter: true,
      resizable: true,
      hide: false,

    },
    {
      field: "Total consideration local ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Trim code",
      filter: true,
      resizable: true,
      hide: true,
    },
    {
      field: "Transaction quantity",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Transaction type",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Security name",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Security ticker",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "ISIN",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Unit cost foreign ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Brokerage foreign ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Transaction tax foreign ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Fx transaction cost foreign ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Total consideration foreign ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Fx rate",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Local ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
    {
      field: "Foreign ccy",
      filter: true,
      resizable: true,
      hide: false,
    },
  ]);
  
  const hideShowColumn = (e) => {
    console.log(e.target.checked);
    const findRequireCol = (field) => {
      return field.field === e.target.value;
    };
    if (!e.target.checked) {
      let requireField = columnDefs.find(findRequireCol);
      columnDefs.forEach((value, index) => {
        if (value === requireField) {
          requireField.hide = true;
          columnDefs.splice(index, 1, requireField);
        }
      });
      gridRef.current.columnApi.setColumnsVisible([e.target.value], false);

      setColumnDefs([...columnDefs]);
    } else {
      let requireField = columnDefs.find(findRequireCol);
      columnDefs.forEach((value, index) => {
        if (value === requireField) {
          requireField.hide = false;
          columnDefs.splice(index, 1, requireField);
        }
      });
      gridRef.current.columnApi.setColumnsVisible([e.target.value], true);

      setColumnDefs([...columnDefs]);
    }
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const savedState = gridRef.current.columnApi.getColumnState();
    console.log(columnDefs,savedState);
    
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`,
        {
          order_json: JSON.stringify(columnDefs),
          visibility_json: JSON.stringify(savedState),
          page:"my_data"
        },
        { headers: headers }
      )
      .then((res) => {
        if(res.data.order_json){
            setColumnDefs(JSON.parse(res.data.order_json))
        }
      });
  };
  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);
  const onColumnMoved = (e) => {
    const savedState = gridRef.current.columnApi.getColumnState();
    let tempMasterColumnState = masterColumnState;
    console.log(masterColumnState);
    tempMasterColumnState["myData"] = savedState;
    setMasterColumnState(tempMasterColumnState);
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences`,
        {
          order_json: JSON.stringify(columnDefs),
          visibility_json: JSON.stringify(tempMasterColumnState),
          page:'my_data'
        },
        { headers: headers }
      )
      .then((res) => {});
  };
  const GetLpTablePosition=(headers)=>{
    axios
    .get(
      `${process.env.REACT_APP_BACKEND_API}finance/lp_table_col_preferences?page=my_data`,
      { headers: headers }
    )
    .then((res) => {
      console.log(">>lp table data", res.data);
      if (res.data.visibility_json) {
        setTimeout(() => {
          const savedState = JSON.parse(res.data.visibility_json);
          if (
            !savedState.myData &&
            !savedState.flatPosition &&
            !savedState.m2m
          ) {
            setMasterColumnState({ old_state: savedState });
          } else {
            console.log("--->>>", savedState);
            setMasterColumnState(savedState);
            gridRef.current.columnApi.applyColumnState({
              state: savedState.myData,
              applyOrder: true,
            });
          }
        }, 2000);
      }

      if (res.data.order_json) {
        setColumnDefs(JSON.parse(res.data.order_json));
      }
      console.log(JSON.parse(res.data.order_json))
    });
  }
  React.useEffect(() => {

    const getAccessToken = async () => {

      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(accessToken)
      let headers = {
        'Authorization': `Bearer ${accessToken}`
      }
      GetLpTablePosition(headers)
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/all-securities`, { headers: headers }).then(response => {
        console.log(response)
      })


    }
    getAccessToken()
  }, []);


  function applyFilter() {
    let headers = {
      'Authorization': `Bearer ${accessToken}`
    }

    if (transaction_type_filter === "all" && !security_ticker) {
      alert.error("PLease specify security/ticker or select different transaction type")
      return
    }

    if (!security_ticker && !transaction_type_filter && !trade_date_filter && typeof transactionType === 'undefined') {
      alert.error("Please select transaction type and trade date")
      return
    }

    console.log(transaction_type_filter, trade_date_filter, security_ticker)

    document.getElementById("applyFilters").innerText = "Please wait..."
    try {

      document.getElementById("apply2").innerText = "Please wait..."
    } catch { }
    GetLpTablePosition(headers)

    axios.get(`${process.env.REACT_APP_BACKEND_API}finance/position/filter?transaction-type=${transaction_type_filter}&tradedate=${trade_date_filter}&from-tradedate=${fromTradeDate}&to-tradedate=${toTradeDate}&security-ticker=${security_ticker}`, { headers: headers })
      .then(response => {
        console.log("Positions",response)
        let finalRowData = []
        if (response.data.positions.length > 0) {
          response.data.positions.forEach((value, index, array) => {
            finalRowData.push({
              "id":value.id,
              "Trade date": value.trade_date,
              "Settlement date": value.settlement_date,
              "Broker refrence": value.broker_refrence,
              "Transaction description": value.transaction_description,
              "Transaction quantity": value.transaction_quantity,
              "Unit cost local ccy": value.unit_cost_local_ccy,
              "Quantity": value.quantity,
              "Brokerage local cyy": value.brokerage_local_ccy,
              "Transaction tax local ccy": value.transaction_tax_local_ccy,
              "Fx transaction cost local ccy": value.fx_transaction_cost_local_ccy,
              "Total consideration local ccy": value.total_consideration_local_ccy,
              "Trim code": value.trim_code,
              "Transaction type": value.transaction_type,
              "Security name": value.security_name,
              "Security ticker": value.security_ticker,
              "ISIN": value.isin,
              "Unit cost foreign ccy": value.unit_cost_foreign_ccy,
              "Brokerage foreign ccy": value.brokerage_foreign_ccy,
              "Transaction tax foreign ccy": value.transaction_tax_foreign_ccy,
              "Fx transaction cost foreign ccy": value.fx_transaction_cost_foreign_ccy,
              "Total consideration foreign ccy": value.total_consideration_foreign_ccy,
              "Fx rate": value.fx_rate,
              "Local ccy": value.local_ccy,
              "Foreign ccy": value.foreign_ccy,

            })
          })
          document.getElementById("applyFilters").innerText = "Apply"
          try {
            document.getElementById("apply2").innerText = "Apply"
          } catch { }

          dispatch({ type: "UPDATE_MYDATA_FLAG", payload: { is_mydata_fetched: true } })
          dispatch({ type: "UPDATE_POSITION", payload: { positions: finalRowData } })
          dispatch({ type: "CHANGE_SECURITY_TICKER", payload: { "securityTicker": "" } })
          setTimeout(() => {
            
            autoSizeAll(false)
          }, 2000);
        } else {
          document.getElementById("applyFilters").innerText = "Apply"
          try {
            document.getElementById("apply2").innerText = "Apply"
          } catch { }
          alert.error("No Data Found")

        }
      }).catch(err => {
        document.getElementById("applyFilters").innerText = "Error"
        try {
          document.getElementById("apply2").innerText = "Error"
        } catch { }

      })
  }
  return (
    <div className="content">

      <Row>
        <Col md="12">
          <Card className="card-chart px-2" style={{ minHeight: "80vh" }}>

            <CardBody>
              {is_mydata_fetched ? (
                <>

                  <UncontrolledCollapse className="py-3" toggler="filterSecurityToggler">
                    <AutoSuggest value={security_name} placeholder="Filter by security (optional)"></AutoSuggest>
                    <button onClick={applyFilter} id="apply2" className="btn btn-sm btn-success">Apply Changes</button>
                  </UncontrolledCollapse>

                  <UncontrolledCollapse className="py-3" toggler="hideShowColumnTrigger">
                    <Card>
                      <CardBody>
                        <div className="row">
                          {columnDefs.map((value, index, array) => {
                            if (value.field.indexOf("_") === -1) {
                              return (
                                <div
                                  className="col-2"
                                  key={index + Math.random(index) * 100}
                                >
                                  <FormGroup
                                    key={index + Math.random(index) * 100}
                                    check
                                  >
                                    <Label key={index + Math.random(index) * 100} check>
                                      <Input
                                        type="checkbox"
                                        value={value.field}
                                        checked={!value.hide}
                                        onChange={(e) => {
                                          hideShowColumn(e);
                                        }}
                                        key={index + Math.random(index) * 100}
                                      />{" "}
                                      <span
                                        key={index + Math.random(index) * 100}
                                        className="form-check-sign"
                                      >
                                        <span
                                          className="check"
                                          key={index + Math.random(index) * 100}
                                        >
                                          {" "}
                                          {value.field}{" "}
                                        </span>
                                      </span>
                                    </Label>
                                  </FormGroup>
                                </div>
                              );
                            }
                          })}
                        </div>{" "}
                      </CardBody>{" "}
                    </Card>{" "}
                  </UncontrolledCollapse>
                </>
              ) : ""}
              <div style={!is_mydata_fetched ? {} : { display: "none" }} >

                <h4 className="card-title mb-4">Please select below filters to get the data.</h4>
                <div className="row mb-4">
                  <div className="col-12" >
                    <AutoSuggest placeholder="Filter by security (optional)"></AutoSuggest>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6" >

                    <select onChange={e => {
                      dispatch({ type: "UPDATE_TRANSACTION_TYPE_FILTER", payload: { transaction_type_filter: e.target.value } })
                    }} className="btn-sm" style={{ width: '99%' }}>
                      <option value="Select Transaction Type">Select Transaction Type</option>
                      <option value="Accounting Transfer">Accounting Transfer</option>
                      <option value="Buy">Buy</option>
                      <option value="Closing Balance">Closing Balance</option>
                      <option value="Deposit">Deposit</option>
                      <option value="Dividend - Equity">Dividend - Equity</option>
                      <option value="Dividend - Fund">Dividend - Fund</option>
                      <option value="Dividend - Loyalty Fund">Dividend - Loyalty Fund</option>
                      <option value="Dividend - Overseas">Dividend - Overseas</option>
                      <option value="Fund Rebate">Fund Rebate</option>
                      <option value="Interest">Interest</option>
                      <option value="LOYALTYU">LOYALTYU</option>
                      <option value="Management Fees">Management Fees</option>
                      <option value="Sell">Sell</option>
                      <option value="Transfer from income account">Transfer from income account</option>
                      <option value="Transfer in capital account">Transfer in capital account</option>
                      <option value="Other in">Other in</option>
                      <option value="Other out">Other out</option>
                      <option value="Unit Rebate">Unit Rebate</option>
                      <option value="all">All</option>
                    </select>
                  </div>

                  <div className="col-6">

                    <select onChange={e => {
                      dispatch({ type: "UPDATE_TRADE_DATE_FILTER", payload: { trade_date_filter: e.target.value } })
                    }} className="btn-sm" style={{ width: '99%' }}>
                      <option value="">Select trade date</option>
                      <option value="last-week">Last Week</option>
                      <option value="last-month">Last Month</option>
                      <option value="last-year">Last year</option>
                      <option value="custom-range">Custom Range</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>


                <div className="px-1 mt-4" style={trade_date_filter === "custom-range" ? { display: '' } : { display: 'none' }}>
                  <h3 className="mb-3">Trade Date</h3>
                  <div className="row">
                    <div className="col-6">
                      <label>From</label>
                      <input value={fromTradeDate} onChange={e => {
                        setFromTradeDate(e.target.value)
                      }} className="form-control" type="date"></input>
                    </div>
                    <div className="col-6">
                      <label>To</label>
                      <input value={toTradeDate} onChange={e => {
                        setToTradeDate(e.target.value)
                      }} className="form-control" type="date"></input>
                    </div>
                  </div>
                </div>
                <button onClick={applyFilter} className="btn btn-secondary mt-5" id="applyFilters">Apply</button>
              </div>
              <div
                className="ag-theme-material live-positions-records"
                style={is_mydata_fetched ? { height: "80vh" } : { height: "70vh", display: "none" }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={positions} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  rowSelection="multiple" // Options - allows click selection of rows
                  rowGroupPanelShow={"always"}
                  pivotPanelShow={"always"}
                  onDragStopped={onColumnMoved} 
                  pagination={true}
                  rowHeight={25}
            headerHeight={35}
                />{" "}
              </div>{" "}
            </CardBody>

          </Card>
        </Col>

      </Row>
    </div>
  )
}

export default MyData