/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "react-alert";

function UserProfile(props) {
  const [portfolioCCY, setPortfolioCCY] = React.useState()
  const [availableCCY, setAvailableCCY] = React.useState([])
  const [accountingMethod, setAccountingMethod] = React.useState([])
  const [userEmail, setUserEmail] = React.useState()
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    const getAvailableCCY = async () => {

      const domain = "mark-to-market.eu.auth0.com";
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      let headers = {
        'Authorization': `Bearer ${accessToken}`

      }
      axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_currencies`, { headers: headers })
        .then(response1 => {
          setAvailableCCY(response1.data)
          axios.get(`${process.env.REACT_APP_BACKEND_API}finance/get_user`, { headers: headers })
            .then(response2 => {
              console.log('>> >> >>', response2)
              setPortfolioCCY(response2.data.portfolio_ccy)
              setAccountingMethod(response2.data.accounting_method)
              setUserEmail(response2.data.email)

            })

        })
    }
    getAvailableCCY()
  }, []);

  const alert = useAlert()

  const chnagePortfolioCCY = async () => {
    let newPortfolioCCY = document.getElementById("new_portfolio_ccy")
    if (newPortfolioCCY.value === portfolioCCY) {
      alert.error("Please select new portfolio ccy")
      return
    }
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = {
      'Authorization': `Bearer ${accessToken}`

    }
    axios.put(`${process.env.REACT_APP_BACKEND_API}finance/set_portfolio_ccy`, { "portfolio_ccy": newPortfolioCCY.value }, { headers: headers })
      .then(response => {
        setPortfolioCCY(newPortfolioCCY.value)
        alert.success("Change successfully")

      }
      )
  }

  const changeAccountingMethod = async () => {
    let accounting_method = document.getElementById("accounting_method")
    console.log(accounting_method.value)
    if (accounting_method.value === accountingMethod) {
      alert.error("Please select new accounting method")
      return
    }
    const domain = "mark-to-market.eu.auth0.com";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    let headers = {
      'Authorization': `Bearer ${accessToken}`

    }
    axios.put(`${process.env.REACT_APP_BACKEND_API}finance/set_accounting_method`, { "accounting_method": accounting_method.value }, { headers: headers })
      .then(response => {
        setAccountingMethod(accounting_method.value)
        alert.success("Change successfully")
      })

  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>

                  {/* <FormGroup>
                    <Label for="exampleSelect1">portfolio ccy</Label>
                    <Input disabled type="select"  name="select" id="new_portfolio_ccy">
                      {availableCCY.map(items => {
                        return (items.currency === portfolioCCY ? <option selected value={items.currency}>{items.currency} - {items.name}</option> : <option value={items.currency}>{items.currency} - {items.name}</option>)
                      })}

                    </Input>
                  </FormGroup> */}

                  <FormGroup className="mt-3">
                    <Label for="exampleSelect1">Pnl calculation</Label>
                    <Input type="select" name="select" id="accounting_method">
                      {accountingMethod == "fifo" ? <option selected value="fifo">FIFO</option> : <option value="fifo">FIFO</option>}
                      {accountingMethod == "lifo" ? <option selected value="lifo">LIFO</option> : <option value="lifo">LIFO</option>}
                      {accountingMethod == "avco" ? <option selected value="avco">AVCO</option> : <option value="avco">AVCO</option>}
                    </Input>
                    <Button onClick={e => {
                      changeAccountingMethod()
                    }} className="btn-fill" color="primary" type="button">
                      Save
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={props.user.picture}
                    />
                    <h5 className="title">{props.user.name}</h5>
                    <h5 className="title">{userEmail}</h5>
                  </a>

                </div>

              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <h4>Portfolio currency: {portfolioCCY}</h4>
            

                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
